import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  Filter,
  DateInput,
  TextInput,
  FilterProps,
  PaginationProps,
  Pagination,
  ResourceComponentPropsWithId,
} from 'react-admin'

import FormattedField from '../common/Fields/FormattedField'
import MonetaryField from '../common/Fields/MonetaryField'
import PersonDocumentField from '../common/Fields/PersonDocumentField'
import TranslatedField from '../common/Fields/TranslatedField'
import WalletUserLinkField from '../common/Fields/WalletUserLinkField'
import CommonListActions from '../common/RA-Overrides/CommonListActions'
import Accounts from './Accounts'

const SearchFilters = (props: Omit<FilterProps, 'children'>): React.ReactElement => (
  <Filter {...props}>
    <DateInput source="dateBegin" alwaysOn />
    <DateInput source="dateEnd" alwaysOn />
    <TextInput source="name" resettable alwaysOn />
    <TextInput source="document" resettable alwaysOn />
  </Filter>
)

const ListPagination = (props: PaginationProps): React.ReactElement => (
  <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100]} />
)

const ListTransactions = (props: ResourceComponentPropsWithId): React.ReactElement => (
  <>
    <Accounts />
    <List
      {...props}
      bulkActionButtons={false}
      filters={<SearchFilters />}
      actions={<CommonListActions />}
      pagination={<ListPagination />}
      sort={{ field: 'timestamp', order: 'DESC' }}
    >
      <Datagrid>
        <DateField source="timestamp" />
        <TranslatedField source="transactionWalletType.id" translationPath="types.TransactionType" />
        <TranslatedField source="status" translationPath="types.BanklyTransactionStatus" />
        <WalletUserLinkField source="name" documentSource="document" />
        <PersonDocumentField source="document" />
        <FunctionField source="walletBank" render={FormattedField.WalletBank} />
        <TextField source="branch" />
        <TextField source="account" />
        <MonetaryField source="amount" colored />
        <MonetaryField source="currentBalance" />
      </Datagrid>
    </List>
  </>
)

export default ListTransactions
