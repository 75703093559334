const Constants = {
  acesso: {
    document: '43.181.893/0001-21',
  },
  exportButton: {
    maxResults: 1000,
  },
}

export default Constants
