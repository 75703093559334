import React from 'react'
import { get, isArray, isEmpty } from 'lodash'
import { useRecordContext } from 'ra-core'
import { Chip, makeStyles } from '@material-ui/core'
import { Check as CheckIcon, Warning as WarningIcon, Clear as CrossIcon } from '@material-ui/icons'

import FieldProps from './FieldProps'
import { WalletStatus } from '../../../config/Types'
import Formatter from '../../../utils/Formatter'
import IconWithHint from '../UI/IconWithHint'

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  chipIcon: {
    // Se você veio aqui copiar esse código, significa que devemos
    // fazer um override do tema.
    paddingLeft: theme.spacing(0.5),
  },
}))

const statusIconResolver = (id: number) => {
  const classes = useStyles()
  if (id === WalletStatus.ACTIVE) return <CheckIcon className={classes.success} fontSize="small" />
  if (id === WalletStatus.BLOCKED) return <CrossIcon fontSize="small" />
  if (id === WalletStatus.SUSPECTED_FRAUD) return <WarningIcon className={classes.error} fontSize="small" />
}

const WalletDetailsField = (props: FieldProps): JSX.Element => {
  const record = useRecordContext(props)
  const classes = useStyles()
  const userWallets = get(record, props.source, null)

  if (isEmpty(userWallets) || !isArray(userWallets)) return <React.Fragment />

  return (
    <React.Fragment>
      {userWallets.map((userWallet) => (
        <Chip
          key={userWallet.id}
          className={classes.chipIcon}
          size="small"
          label={`${userWallet.personaWallet.name} | ${Formatter.shortDate(userWallet.wallet.createdAt)}`}
          icon={
            <IconWithHint
              id={userWallet.wallet.status.id}
              hint={`${userWallet.wallet.status.name}`}
              iconResolver={statusIconResolver}
            />
          }
        />
      ))}
    </React.Fragment>
  )
}

export default WalletDetailsField
