import React from 'react'
import TextMaskedInput, { Mask, MaskedInputProps } from 'react-text-mask'

interface MaskedInputWrapperProps extends MaskedInputProps {
  inputRef: React.LegacyRef<TextMaskedInput>
  mask: Mask | ((value: string) => Mask)
}

/**
 * Componente utilizado apenas para encapsular o MaskedInput e passar a prop de referencia correta.
 */
const MaskedInputWrapper: React.FunctionComponent<MaskedInputWrapperProps> = (props) => {
  const { inputRef, mask, ...other } = props
  return <TextMaskedInput {...other} ref={inputRef} mask={mask} placeholderChar={'\u2000'} showMask />
}

export default MaskedInputWrapper
