import * as React from 'react'
import { List, Datagrid, TextField, BooleanField } from 'react-admin'

const ListWalletTransactionReasons: React.FunctionComponent = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <BooleanField source="active" sortable={false} />
    </Datagrid>
  </List>
)

export default ListWalletTransactionReasons
