import { isNumber } from 'lodash'
import { BrazilUf, Gender, PersonaTypes, PersonType, TransactionStatus, WalletStatus } from './Types'

export default {
  BrazilStates: Object.values(BrazilUf).map((uf) => ({ id: uf, name: `types.BrazilUf.${uf}` })),
  Genders: Object.values(Gender).map((gender) => ({ id: gender, name: `types.Gender.${gender}` })),
  PersonTypes: Object.values(PersonType).map((type) => ({ id: type, name: type })),
  PersonTypesLong: Object.values(PersonType).map((type) => ({ id: type, name: `types.PersonType.${type}` })),
  WalletStatus: Object.values(WalletStatus)
    .filter(isNumber)
    .map((type) => ({ id: type, name: `types.WalletStatus.${type}` })),
  PersonaTypes: Object.values(PersonaTypes)
    .filter(isNumber)
    .map((type) => ({ id: type, name: `types.PersonaTypes.${type}` })),
  TransactionStatuses: Object.values(TransactionStatus)
    .filter(isNumber)
    .map((ts) => ({ id: ts, name: `types.TransactionStatus.${ts}` })),
}
