import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  DateInput,
  FilterProps,
  PaginationProps,
  Pagination,
  ResourceComponentPropsWithId,
  SelectInput,
} from 'react-admin'
import { Choices } from '../../config'

const SearchFilters = (props: Omit<FilterProps, 'children'>): React.ReactElement => (
  <Filter {...props}>
    <SelectInput source="persona" choices={Choices.PersonaTypes} alwaysOn />
    <DateInput source="dateBegin" alwaysOn />
    <DateInput source="dateEnd" alwaysOn />
  </Filter>
)

const ListPagination = (props: PaginationProps): React.ReactElement => (
  <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100]} />
)

const ListNotifications = (props: ResourceComponentPropsWithId): React.ReactElement => (
  <>
    <List
      {...props}
      bulkActionButtons={false}
      filters={<SearchFilters />}
      pagination={<ListPagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <DateField source="createdAt" />
        <TextField source="walletNotificationBroadcast.message" />
        <TextField source="walletPersona.name" />
      </Datagrid>
    </List>
  </>
)

export default ListNotifications
