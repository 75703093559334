import React from 'react'

import { ArrayField, ChipField, SingleFieldList } from 'react-admin'

const GroupUserField = (): JSX.Element => {
  return (
    <ArrayField source="groupUsers">
      <SingleFieldList linkType={false}>
        <ChipField source="group.name" />
      </SingleFieldList>
    </ArrayField>
  )
}

export default GroupUserField
