import { get } from 'lodash'
import { DataProvider } from 'ra-core'

import { BackendRoutes, Entities } from '../config'
import { httpRequest } from '../networking'
import { QueryParam } from '../networking/http-request'

export enum Methods {
  getList,
  getOne,
  getMany,
  update,
  create,
  delete,
}

/**
 * Converte queries do react-admin para o padrão REST do backend.
 * Todas as comunicações de recursos devem ser realizadas através dos provedores de dados,
 * partindo do pressuposto que a API é padronizada (parâmetros de ordenação, filtragem, metadados, etc).
 * Documentação: https://marmelab.com/react-admin/DataProviders.html
 *
 * Referências extras:
 * [1] Requisições agrupadas: a API não suporta nativamente requisições multi-registro (por ID).
 * O react-admin oferece essas interfaces (getMany, updateMany, deleteMany) como uma otimização
 * para esse tipo de cenário. Para manter a compatibilidade do app com a api, mapeamos manualmente
 * essas requisições para realizá-las paralelamente, retornando-as em conjunto.
 */
const createDataProvider = (): DataProvider => ({
  /**
   * GET tradicional para listar todos os itens (filtrados ou não) da API.
   */
  getList: async (resource, params) => {
    const path = BackendRoutes.resolveRoute(Methods.getList, resource as Entities)
    const query = { ...params.filter } as QueryParam
    if (params.sort) {
      query.orderBy = params.sort.field
      query.isOrderByDesc = params.sort.order === 'DESC'
    }
    if (params.pagination) {
      query.currentPage = params.pagination.page
      query.itemsPerPage = params.pagination.perPage
    }
    const response = await httpRequest({ verb: 'GET', path, query })
    return {
      data: get(response, 'data.payload', []),
      total: get(response, 'data.metadata.totalOfRegisters', 0),
    }
  },

  /**
   * GET tradicional para obter um item.
   */
  getOne: async (resource, { id, ...query }) => {
    const route = BackendRoutes.resolveRoute(Methods.getOne, resource as Entities)
    const path = `${route}\\${id}`
    const response = await httpRequest({ verb: 'GET', path, query: query as QueryParam })
    return { data: get(response, 'data') }
  },

  /**
   * Obtém N items em uma única request. [1]
   */
  getMany: async (resource, params) => {
    const route = BackendRoutes.resolveRoute(Methods.getOne, resource as Entities)
    const paths = params.ids.map((id) => `${route}\\${id}`)
    const requests = paths.map((path) => httpRequest({ verb: 'GET', path }))
    const responses = await Promise.all(requests)
    const data = responses.map((response) => get(response, 'data.payload', null))
    return { data }
  },

  /**
   * GET relacional, le uma lista de itens a partir do ID de outra entity.
   */
  getManyReference: async (resource, params) => {
    const path = BackendRoutes.resolveRoute(Methods.getMany, resource as Entities)
    const query = {
      [params.target]: params.id,
      orderBy: params.sort.field,
      isOrderByDesc: params.sort.order === 'DESC',
      currentPage: params.pagination.page,
      itemsPerPage: params.pagination.perPage,
      ...params.filter,
    }
    const response = await httpRequest({ verb: 'GET', path, query })
    return {
      data: get(response, 'data.payload', []),
      total: get(response, 'data.metadata.totalOfRegisters', 0),
    }
  },

  /**
   * Cria um registro.
   */
  create: async (resource, params) => {
    const path = BackendRoutes.resolveRoute(Methods.create, resource as Entities)
    const { data } = params
    const response = await httpRequest({ verb: 'POST', path, data })
    const entity = get(response, 'data')
    return { data: entity || data }
  },

  /**
   * Atualiza um item.
   */
  update: async (resource, params) => {
    const route = BackendRoutes.resolveRoute(Methods.update, resource as Entities)
    const path = `${route}\\${params.id}`
    const { data } = params
    const response = await httpRequest({ verb: 'PUT', path, data })
    const dataApi = get(response, 'data', false)
    return { data: dataApi || data }
  },

  /**
   * Atualiza N itens em uma única request. [1]
   */
  updateMany: async (resource, params) => {
    const route = BackendRoutes.resolveRoute(Methods.update, resource as Entities)
    const requests = params.ids.map(async (id) => {
      const path = `${route}\\${id}`
      await httpRequest({ verb: 'PUT', path, data: params.data })
      return id
    })
    const ids = await Promise.all(requests)
    return { data: ids }
  },

  /**
   * Exclui um registro.
   */
  delete: async (resource, params) => {
    const route = BackendRoutes.resolveRoute(Methods.delete, resource as Entities)
    const path = `${route}\\${params.id}`
    const response = await httpRequest({ verb: 'DELETE', path })
    // TODO: Verificar se isso está correto
    const success = get(response, 'data', false)
    if (success) {
      return { data: params.previousData as any }
    }
    throw new Error(get(response, 'msg', 'Failure deleting record'))
  },

  /**
   * Exclui N registros. [1]
   */
  deleteMany: async (resource, params) => {
    const route = BackendRoutes.resolveRoute(Methods.delete, resource as Entities)
    const requests = params.ids.map(async (id) => {
      const path = `${route}\\${id}`
      await httpRequest({ verb: 'DELETE', path })
      return id
    })
    const ids = await Promise.all(requests)
    return { data: ids }
  },
})

export default createDataProvider()
