import React from 'react'
import { FormTabProps, ListBase, ListToolbar, Pagination, ResourceContextProvider, useRecordContext } from 'react-admin'

import { BackendRoutes, Entities } from '../../../config'
import { Methods } from '../../../providers/dataProvider'
import { Currency } from '../../../config/Types'

import FormTab from '../../common/RA-Overrides/FormTab'
import SearchFilters from './UserTransactionsFilters'
import UserTransactionsDatagrid from './UserTransactionsDatagrid'

const TabUserTransactions = (props: Omit<FormTabProps, 'label'>): React.ReactElement => {
  const record = useRecordContext(props)

  if (!record) return <React.Fragment />

  return (
    <FormTab {...props} label={`resources.${Entities.WALLET_TRANSACTIONS}.name`}>
      <ResourceContextProvider value={Entities.WALLET_TRANSACTIONS}>
        <ListBase
          basePath={BackendRoutes.resolveRoute(Methods.getList, Entities.WALLET_TRANSACTIONS)}
          filter={{ userId: record.id }}
          filterDefaultValues={{ walletCoinId: Currency.BRL }}
          sort={{ field: 'transactionWallet.createdAt', order: 'DESC' }}
        >
          <ListToolbar filters={<SearchFilters userId={record.id} />} />
          <UserTransactionsDatagrid />
          <Pagination />
        </ListBase>
      </ResourceContextProvider>
    </FormTab>
  )
}

export default TabUserTransactions
