import React from 'react'
import { composeValidators, InputProps, TextInput, TextInputProps, useTranslate } from 'react-admin'

import { onlyNumbers } from '../../../utils/Formatter'
import MaskedInputWrapper from './MaskedInputWrapper'

const zipCodeMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/]

const inputProps: Partial<InputProps> = { inputComponent: MaskedInputWrapper }

/**
 * Certifica que o valor, após remoção da formatação tem apenas 8 digitos.
 */
const zipCodeValidator =
  (message = 'validation.zipCode.length') =>
  (value: string) =>
    value && onlyNumbers(value).length === 8 ? undefined : message

const ZipCodeInput = (props: TextInputProps): JSX.Element => {
  const translate = useTranslate()
  return (
    <TextInput
      {...props}
      label={translate(`resources.${props.resource}.fields.${props.source}`)}
      validate={composeValidators(zipCodeValidator(), props.validate)}
      InputProps={inputProps}
      inputProps={{ mask: zipCodeMask }}
      InputLabelProps={{ shrink: true }}
    />
  )
}

export default ZipCodeInput
