import React from 'react'
import { useGetOne, useTranslate } from 'react-admin'
import { Box, List, ListItem, ListItemText } from '@material-ui/core'

import Entities from '../../config/Entities'
import { Currency } from '../../config/Types'
import Formatter from '../../utils/Formatter'
import Constants from '../../config/Constants'

const Accounts = (): JSX.Element => {
  const translate = useTranslate()
  const { data } = useGetOne(Entities.ACCOUNTS, 'default')
  if (!data) {
    return <React.Fragment></React.Fragment>
  }
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <List>
        <ListItem>
          <ListItemText
            primary={translate(`resources.${Entities.TRANSACTIONS}.fields.branch`)}
            secondary={data.branch}
          />
          <ListItemText
            primary={translate(`resources.${Entities.TRANSACTIONS}.fields.account`)}
            secondary={data.number}
          />
          <ListItemText
            primary={translate(`resources.${Entities.TRANSACTIONS}.fields.document`)}
            secondary={Constants.acesso.document}
          />
          <ListItemText
            primary={translate(`resources.${Entities.TRANSACTIONS}.fields.balance`)}
            secondary={Formatter.currency(Currency.BRL, data.balance.available.amount)}
          />
        </ListItem>
      </List>
    </Box>
  )
}

export default Accounts
