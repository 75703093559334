import React, { Children, ReactElement } from 'react'
import {
  ArrayField,
  Datagrid,
  DatagridBody,
  DatagridBodyProps,
  DatagridProps,
  DatagridRowProps,
  DateField,
  FieldProps,
  FormInput,
  FunctionField,
  SingleFieldList,
  TextField,
} from 'react-admin'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

interface RowFieldsWrapperProps extends FieldProps {
  basePath?: string
  children: JSX.Element | JSX.Element[]
}

/**
 * Wrapper para renderizar os campos em linha (TableRow) com respectivas columns (TableCell) e
 * adicionar labels (via FormInput).
 */
const RowFieldsWrapper = (props: RowFieldsWrapperProps): ReactElement => (
  <TableRow>
    {Children.map(
      props.children,
      (input: ReactElement) =>
        input && (
          <TableCell>
            <FormInput basePath={props.basePath || ''} input={input} record={props.record} resource={props.resource} />
          </TableCell>
        )
    )}
  </TableRow>
)

const WalletDetailsDatagridRow = (props: DatagridRowProps): ReactElement => {
  const { record, resource, id, basePath } = props
  const fieldProps: FieldProps = { record, basePath, resource }
  const hasSponsorData = !!record?.walletSponsor
  const hasNicknamesData = record?.nicknames?.length > 0
  return (
    <React.Fragment key={id}>
      <RowFieldsWrapper {...fieldProps}>
        <TextField source="pan" />
        {/* Essa data segue o formato mm/yyyy e já vem formatada da API */}
        <TextField source="expirationDate" />
        <DateField source="createdAt" />
        <TextField source="status.name" />
        <DateField source="status.createdAt" />
      </RowFieldsWrapper>
      <RowFieldsWrapper {...fieldProps}>
        <TextField source="slug" />
        <TextField source="personaWalletName" />
        <TextField source="walletTeam.name" />
      </RowFieldsWrapper>
      {hasSponsorData && (
        <RowFieldsWrapper {...fieldProps}>
          <TextField source="walletSponsor" />
          <TextField source="walletSponsorCode" />
        </RowFieldsWrapper>
      )}
      {hasNicknamesData && (
        <RowFieldsWrapper {...fieldProps}>
          <ArrayField source="nicknames">
            <SingleFieldList linkType={false}>
              <FunctionField
                source="name"
                render={(nickname: any) => `${nickname?.account.companyName}: ${nickname?.name}`}
              />
            </SingleFieldList>
          </ArrayField>
        </RowFieldsWrapper>
      )}
    </React.Fragment>
  )
}

const WalletDetailsDatagridBody = (props: DatagridBodyProps): ReactElement => (
  <DatagridBody {...props} row={<WalletDetailsDatagridRow />} />
)
const WalletDetailsDatagrid = (props: DatagridProps): ReactElement => (
  <Datagrid {...props} body={<WalletDetailsDatagridBody />} />
)

export default WalletDetailsDatagrid
