import React from 'react'
import {
  composeValidators,
  Edit,
  Loading,
  PasswordInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetIdentity,
  useNotify,
  useRedirect,
  useUpdate,
} from 'react-admin'

import { BackendRoutes, Entities } from '../../config'
import { matchField } from '../../utils/Validator'
import PhoneInput from '../common/Inputs/PhoneInput'
import { Methods } from '../../providers/dataProvider'
import GroupUserField from '../common/Fields/GroupUserField'

export const ProfileEdit = (): JSX.Element => {
  const { identity, loading } = useGetIdentity()
  const redirect = useRedirect()
  const [update] = useUpdate()
  const notify = useNotify()

  const handleSave = (user: any) => {
    update(Entities.MY_PROFILE, user.id, {
      phone: user.phone,
      password: user.password,
    })

    notify('ra.notification.updated', 'success')
    redirect('/')
  }

  const ProfileEditToolbar = () => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  )

  if (loading) return <Loading />

  return (
    <Edit
      id={String(identity?.id)}
      resource={Entities.MY_PROFILE}
      basePath={BackendRoutes.resolveRoute(Methods.update, Entities.MY_PROFILE)}
    >
      <SimpleForm save={handleSave} toolbar={ProfileEditToolbar()}>
        <TextInput source="name" disabled />
        <TextInput source="email" disabled />
        <PhoneInput source="phone" />
        <PasswordInput source="password" validate={required()} />
        <PasswordInput
          source="passwordConfirm"
          validate={composeValidators(matchField('password', 'validation.match.passwordDiff'), required())}
        />
        <GroupUserField />
      </SimpleForm>
    </Edit>
  )
}

export default ProfileEdit
