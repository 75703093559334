import React from 'react'
import {
  Datagrid,
  TextField,
  FormTab,
  ResourceContextProvider,
  ListBase,
  useRecordContext,
  BooleanField,
  Pagination,
  FormTabProps,
  ListToolbar,
} from 'react-admin'
import { BackendRoutes } from '../../../config'

import Entities from '../../../config/Entities'
import { Methods } from '../../../providers/dataProvider'
import PersonDocumentField from '../../common/Fields/PersonDocumentField'
import NullableBooleanField from '../../common/Fields/NullableBooleanField'
import SearchFilters from './UserAccountsFilters'

const TabUserAccounts = (props: Omit<FormTabProps, 'label'>): React.ReactElement => {
  const record = useRecordContext(props)
  if (!record) return <React.Fragment />
  return (
    <FormTab {...props} label={`resources.${Entities.WALLET_USER_ACCOUNTS}.name`}>
      <ResourceContextProvider value={Entities.WALLET_USER_ACCOUNTS}>
        <ListBase
          basePath={BackendRoutes.resolveRoute(Methods.getList, Entities.WALLET_USER_ACCOUNTS)}
          filter={{ userId: record.id }}
          sort={{ field: 'isDefaultAccount', order: 'DESC' }}
        >
          <ListToolbar filters={<SearchFilters />} />
          <Datagrid>
            <PersonDocumentField source="document" sortable={false} />
            <TextField source="branch" sortable={false} />
            <TextField source="number" sortable={false} />
            <BooleanField source="isDefaultAccount" />
            <TextField source="pixKey" sortable={false} />
            <NullableBooleanField
              source="deletedAt"
              valueLabelTrue={`resources.${Entities.WALLET_USER_ACCOUNTS}.status.active`}
              valueLabelFalse={`resources.${Entities.WALLET_USER_ACCOUNTS}.status.inactive`}
              sortable={false}
            />
            <TextField source="walletBank.shortName" sortable={false} />
            <TextField source="walletBank.code" sortable={false} />
          </Datagrid>
          <Pagination />
        </ListBase>
      </ResourceContextProvider>
    </FormTab>
  )
}

export default TabUserAccounts
