import React from 'react'
import {
  Datagrid,
  DateField,
  FormTabProps,
  FunctionField,
  ListBase,
  Pagination,
  ResourceContextProvider,
  TextField,
  useRecordContext,
} from 'react-admin'

import { BackendRoutes, Entities, Locales } from '../../../config'
import { Methods } from '../../../providers/dataProvider'

import FormTab from '../../common/RA-Overrides/FormTab'
import FormattedField from '../../common/Fields/FormattedField'
import MonetaryField from '../../common/Fields/MonetaryField'

const TabUserSolicitations = (props: Omit<FormTabProps, 'label'>): React.ReactElement => {
  const record = useRecordContext(props)
  if (!record) return <React.Fragment />
  return (
    <FormTab {...props} label={`resources.${Entities.WALLET_SOLICITATIONS}.name`}>
      <ResourceContextProvider value={Entities.WALLET_SOLICITATIONS}>
        <ListBase
          basePath={BackendRoutes.resolveRoute(Methods.getList, Entities.WALLET_SOLICITATIONS)}
          filter={{ userId: record.id }}
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <Datagrid>
            <DateField source="createdAt" showTime options={{ timezone: Locales.BROWSER_TIMEZONE }} sortable={false} />
            <MonetaryField source="value" transactionTypeIdSource="transactionWalletType.id" colored sortable={false} />
            <TextField source="transactionWalletSolicitationStatus.name" sortable={false} />
            <TextField source="transactionWalletSubType.name" sortable={false} />
            <FunctionField source="userWalletAccount.walletBank" render={FormattedField.WalletBank} sortable={false} />
          </Datagrid>
          <Pagination />
        </ListBase>
      </ResourceContextProvider>
    </FormTab>
  )
}

export default TabUserSolicitations
