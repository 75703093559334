// As variáveis são necessárias mesmo sem uso devido ao uso do operador spread
// para o restante das props.
/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react'
import { FormGroupContextProvider } from 'ra-core'
import { FormTabHeader, FormTabProps } from 'react-admin'

const hiddenStyle = { display: 'none' }

/**
 * Esta é um "override" do componente FormTab do react-admin que tem como
 * intuito não renderizar todos os elementos children dentro de um FormInput,
 * como faz o componente original. Essa diferença permite a criação de FormTabs
 * com layouts personalizados.
 */
const FormTab = (props: FormTabProps): React.ReactElement => {
  const {
    basePath,
    className,
    classes,
    contentClassName,
    children,
    hidden,
    icon,
    intent,
    label,
    margin,
    path,
    record,
    resource,
    variant,
    value,
    ...rest
  } = props

  const renderHeader = () => (
    <FormTabHeader label={label} value={value} icon={icon} className={className} classes={classes} {...rest} />
  )

  const renderContent = () => (
    <FormGroupContextProvider name={value?.toString() || ''}>
      <span
        style={hidden ? hiddenStyle : undefined}
        className={contentClassName}
        id={`tabpanel-${value}`}
        aria-labelledby={`tabheader-${value}`}
        // Set undefined instead of false because WAI-ARIA Authoring Practices 1.1
        // notes that aria-hidden="false" currently behaves inconsistently across browsers.
        aria-hidden={hidden || undefined}
      >
        {children}
      </span>
    </FormGroupContextProvider>
  )

  return intent === 'header' ? renderHeader() : renderContent()
}

FormTab.displayName = 'FormTab'

export default FormTab
