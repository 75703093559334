import React from 'react'
import { get } from 'lodash'
import { useRecordContext, useTranslate } from 'react-admin'

import FieldProps from './FieldProps'

interface TrasnlationFieldProps extends FieldProps {
  translationPath: string
}

const TranslatedField = (props: TrasnlationFieldProps): JSX.Element => {
  const record = useRecordContext(props)
  const translate = useTranslate()
  const value = get(record, props.source, null)
  return <React.Fragment>{translate(`${props.translationPath}.${value}`)}</React.Fragment>
}

export default TranslatedField
