import React from 'react'
import { composeValidators, InputProps, TextInput, TextInputProps, useTranslate } from 'react-admin'

import MaskedInputWrapper from './MaskedInputWrapper'

const cnpjMask = [
  /[0-9]/,
  /[0-9]/,
  '.',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '.',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '/',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
]

const inputProps: Partial<InputProps> = { inputComponent: MaskedInputWrapper }

/**
 * Certifica que o valor, após remoção da formatação tem apenas 12 digitos.
 */
const cnpjValidator =
  (message = 'validation.cnpj.length') =>
  (value: string) =>
    value?.length === 14 ? undefined : message

const CnpjInput = (props: TextInputProps): JSX.Element => {
  const translate = useTranslate()
  return (
    <TextInput
      {...props}
      label={translate(`resources.${props.resource}.fields.${props.source}`)}
      validate={composeValidators(cnpjValidator(), props.validate)}
      InputProps={inputProps}
      inputProps={{ mask: cnpjMask }}
      InputLabelProps={{ shrink: true }}
    />
  )
}

export default CnpjInput
