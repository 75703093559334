import React from 'react'
import { useTranslate } from 'react-admin'
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { FilterList as FilterIcon, Today as TodayIcon } from '@material-ui/icons'

const tips = [
  { id: 'filters', icon: <FilterIcon /> },
  { id: 'dates', icon: <TodayIcon /> },
]

const WelcomePage = (): JSX.Element => {
  const translate = useTranslate()
  return (
    <React.Fragment>
      <Card>
        <CardHeader title={translate('pages.welcome.helpCardTitle')} />
        <CardContent>
          <Typography variant="body1">
            <List dense>
              {tips.map((tip) => (
                <ListItem key={tip.id}>
                  <ListItemIcon>{tip.icon}</ListItemIcon>
                  <ListItemText>{translate(`pages.welcome.tips.${tip.id}`)}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Typography>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}

export default WelcomePage
