import * as React from 'react'
import { Create, SimpleForm, BooleanInput, TextInput } from 'react-admin'

const CreateGroups: React.FunctionComponent = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" required />
      <TextInput source="description" required />
      <BooleanInput source="active" required />
    </SimpleForm>
  </Create>
)

export default CreateGroups
