import React from 'react'
import {
  SelectInput,
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  FunctionField,
  Pagination,
  ResourceComponentPropsWithId,
  PaginationProps,
  FilterProps,
  DateInput,
} from 'react-admin'

import Choices from '../../config/Choices'
import Entities from '../../config/Entities'
import PermissionConst from '../../config/Permissions'
import { Methods } from '../../providers/dataProvider'
import { userHasPermission } from '../../utils/Permission'
import FormattedField from '../common/Fields/FormattedField'
import PersonDocumentField from '../common/Fields/PersonDocumentField'
import WalletDetailsField from '../common/Fields/WalletDetailsField'
import CommonListActions from '../common/RA-Overrides/CommonListActions'

const ListFilters = (props: Omit<FilterProps, 'children'>): React.ReactElement => (
  <Filter {...props}>
    <TextInput
      source="fullName"
      label={`resources.${Entities.WALLET_USERS}.fields.userDetailWallet.fullName`}
      resettable
      alwaysOn
    />
    <SelectInput source="personType" choices={Choices.PersonTypesLong} alwaysOn />
    <SelectInput source="status" choices={Choices.WalletStatus} alwaysOn />
    <TextInput source="document" resettable alwaysOn />
    <TextInput
      source="phoneNumber"
      label={`resources.${Entities.WALLET_USERS}.fields.userDetailWallet.phoneNumber`}
      resettable
      alwaysOn
    />
    <TextInput source="email" resettable alwaysOn />
    <DateInput source="dateBegin" alwaysOn label={`resources.${Entities.TRANSACTIONS_TRIAGE}.fields.dateBegin`} />
    <DateInput source="dateEnd" alwaysOn label={`resources.${Entities.TRANSACTIONS_TRIAGE}.fields.dateEnd`} />
  </Filter>
)

const ListPagination = (props: PaginationProps): React.ReactElement => (
  <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100]} />
)

const WalletUsersList = (props: ResourceComponentPropsWithId): React.ReactElement => {
  const userCanEdit = userHasPermission(PermissionConst[Entities.WALLET_USERS][Methods.update])
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={<CommonListActions />}
      filters={<ListFilters />}
      pagination={<ListPagination />}
    >
      <Datagrid rowClick={userCanEdit ? 'edit' : 'show'}>
        <TextField source="id" />
        <TextField source="userDetailWallet.fullName" />
        <PersonDocumentField source="document" sortable={false} />
        <FunctionField source="userDetailWallet.phoneNumber" render={FormattedField.PhoneNumber} sortable={false} />
        <TextField source="email" sortable={false} />
        <WalletDetailsField source="userWallets" sortable={false} />
      </Datagrid>
    </List>
  )
}

export default WalletUsersList
