import React from 'react'
import { required, SelectInput, TextInput, useTranslate } from 'react-admin'
import { Box, Typography } from '@material-ui/core'

import { Choices, Entities } from '../../../config'
import ZipCodeInput from '../../common/Inputs/ZipCodeInput'

interface SectionAddressProps {
  disabled?: boolean
}

const SectionAddress = (props: SectionAddressProps): React.ReactElement => {
  const translate = useTranslate()
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {translate(`resources.${Entities.WALLET_USERS}.sections.address`)}
      </Typography>
      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            disabled={props.disabled}
            resource={Entities.WALLET_USERS}
            source="userDetailWallet.address"
            validate={required()}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            disabled={props.disabled}
            resource={Entities.WALLET_USERS}
            source="userDetailWallet.addressNumber"
            validate={required()}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            disabled={props.disabled}
            source="userDetailWallet.addressComplement"
            resource={Entities.WALLET_USERS}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={1.25} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            disabled={props.disabled}
            source="userDetailWallet.addressNeighborhood"
            resource={Entities.WALLET_USERS}
            validate={required()}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            disabled={props.disabled}
            source="userDetailWallet.addressCity"
            resource={Entities.WALLET_USERS}
            validate={required()}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <SelectInput
            disabled={props.disabled}
            source="userDetailWallet.addressState"
            resource={Entities.WALLET_USERS}
            choices={Choices.BrazilStates}
            validate={required()}
            fullWidth
          />
        </Box>
        <Box flex={0.75} mr={{ xs: 0, sm: '0.5em' }}>
          <ZipCodeInput
            disabled={props.disabled}
            source="userDetailWallet.addressZipCode"
            resource={Entities.WALLET_USERS}
            validate={required()}
            isRequired
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  )
}

export default SectionAddress
