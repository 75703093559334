import React from 'react'
import { BooleanInput, Edit, SimpleForm, TextInput } from 'react-admin'

const EditWalletTransactionReason: React.FunctionComponent = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
)

export default EditWalletTransactionReason
