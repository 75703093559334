import React from 'react'
import { FormTabProps, ListBase, ResourceContextProvider, useRecordContext } from 'react-admin'

import { BackendRoutes, Entities } from '../../../config'
import PermissionConst from '../../../config/Permissions'
import { Methods } from '../../../providers/dataProvider'
import { useAnonymizedContext } from '../../common/Context/AnonymizedDataContext'
import FormTab from '../../common/RA-Overrides/FormTab'

import WalletDetailsDatagrid from './WalletDetailsDatagrid'

const TabWalletDetails = (props: Omit<FormTabProps, 'label'>): React.ReactElement => {
  const record = useRecordContext(props)
  const { isAnonymized } = useAnonymizedContext(PermissionConst[Entities.WALLETS].TURN_OFF_ANONYMIZATION)
  return (
    <FormTab {...props} label={`resources.${Entities.WALLET_USERS}.tabs.walletDetails`}>
      <ResourceContextProvider value={Entities.WALLETS}>
        <ListBase
          basePath={BackendRoutes.resolveRoute(Methods.getList, Entities.WALLETS)}
          filter={{ userId: record.id, turnOffAnonymization: !isAnonymized }}
        >
          <WalletDetailsDatagrid resource={Entities.WALLETS} />
        </ListBase>
      </ResourceContextProvider>
    </FormTab>
  )
}

export default TabWalletDetails
