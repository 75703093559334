import React, { FC, useEffect } from 'react'
import {
  Edit,
  ResourceComponentPropsWithId,
  SaveButton,
  TabbedForm,
  Toolbar,
  ToolbarProps,
  useRedirect,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'

import { Entities } from '../../config'
import { Methods } from '../../providers/dataProvider'
import PermissionConst from '../../config/Permissions'
import { userHasPermission } from '../../utils/Permission'
import { AnonymizedContextProvider } from '../common/Context/AnonymizedDataContext'
import TurnOffAnonymizationButton from '../common/Buttons/TurnOffAnonymizationButton'

import { Tabs } from './Tabs'
import TabUserAccounts from './TabUserAccounts'
import TabUserDetails from './TabUserDetails'
import TabUserSolicitations from './TabUserSolicitations'
import TabUserTransactions from './TabUserTransactions'
import TabWalletDetails from './TabWalletDetails'

// Se vc veio copiar esse estilo, considere torná-lo reutilizável
const useStyles = makeStyles((theme) => ({
  toolbarButton: {
    marginLeft: theme.spacing(1),
  },
}))

const anonymizationPermissions = [
  PermissionConst[Entities.WALLET_USERS].TURN_OFF_ANONYMIZATION,
  PermissionConst[Entities.WALLETS].TURN_OFF_ANONYMIZATION,
]

const FormToolbar: FC = (props: ToolbarProps) => {
  const classes = useStyles()

  const isUserDetailsTab = window.location.href.endsWith(Tabs.UserDetails)
  const isWalletDetailsTab = window.location.href.endsWith(Tabs.Wallets)

  const userCanEditClientDetails = userHasPermission(PermissionConst[Entities.WALLET_USERS][Methods.update])
  const userCanDisableAnonymization = userHasPermission(...anonymizationPermissions)

  const isAnonymizedTab = isUserDetailsTab || isWalletDetailsTab
  const renderToolbar = isUserDetailsTab || (isAnonymizedTab && userCanDisableAnonymization)

  if (!renderToolbar) return null

  return (
    <Toolbar {...props}>
      {isUserDetailsTab && <SaveButton disabled={!userCanEditClientDetails} redirect={false} />}
      {isAnonymizedTab && userCanDisableAnonymization && (
        <TurnOffAnonymizationButton className={classes.toolbarButton} />
      )}
    </Toolbar>
  )
}

const WalletUserEdit = (props: ResourceComponentPropsWithId): React.ReactElement => {
  const redirect = useRedirect()

  const showWalletDetails = userHasPermission(PermissionConst[Entities.WALLETS][Methods.getList])
  const showUserDetails = userHasPermission(PermissionConst[Entities.WALLET_USERS][Methods.getList])
  const showTransactions = userHasPermission(PermissionConst[Entities.WALLET_TRANSACTIONS][Methods.getList])
  const showSolicitations = userHasPermission(PermissionConst[Entities.WALLET_SOLICITATIONS][Methods.getList])
  const showAccounts = userHasPermission(PermissionConst[Entities.WALLET_USER_ACCOUNTS][Methods.getList])

  useEffect(() => {
    // Garante que uma aba será selecionada na primeira renderização da tela
    redirect(() => `${props.basePath}/${props.id}/${Tabs.UserDetails}`)
  }, [])

  return (
    <AnonymizedContextProvider permissions={anonymizationPermissions}>
      <Edit mutationMode="pessimistic" actions={false} {...props}>
        <TabbedForm toolbar={<FormToolbar />}>
          {showUserDetails && <TabUserDetails path={Tabs.UserDetails} />}
          {showWalletDetails && <TabWalletDetails path={Tabs.Wallets} />}
          {showTransactions && <TabUserTransactions path={Tabs.Transactions} />}
          {showSolicitations && <TabUserSolicitations path={Tabs.Solicitations} />}
          {showAccounts && <TabUserAccounts path={Tabs.Accounts} />}
        </TabbedForm>
      </Edit>
    </AnonymizedContextProvider>
  )
}

export default WalletUserEdit
