import * as React from 'react'
import { BooleanInput, DateField, Edit, SaveButton, SimpleForm, TextInput, Toolbar, ToolbarProps } from 'react-admin'

const EditGroupToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const EditGroups: React.FunctionComponent = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<EditGroupToolbar />}>
      <TextInput source="name" required />
      <TextInput source="description" required />
      <BooleanInput source="active" required />
      <DateField source="updatedAt" />
    </SimpleForm>
  </Edit>
)

export default EditGroups
