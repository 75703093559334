import React from 'react'
import { email, required, TextInput, useTranslate } from 'react-admin'
import { Box, Typography } from '@material-ui/core'

import { Entities } from '../../../config'
import PhoneInput from '../../common/Inputs/PhoneInput'

interface SectionContactsProps {
  disabled?: boolean
}

const SectionContacts = (props: SectionContactsProps): React.ReactElement => {
  const translate = useTranslate()
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {translate(`resources.${Entities.WALLET_USERS}.sections.contacts`)}
      </Typography>
      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            type="email"
            source="email"
            resource={Entities.WALLET_USERS}
            validate={[email(), required()]}
            disabled
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <PhoneInput
            disabled={props.disabled}
            resource={Entities.WALLET_USERS}
            source="userDetailWallet.phoneNumber"
            fullWidth
          />
        </Box>
        <Box flex={1} />
      </Box>
    </Box>
  )
}

export default SectionContacts
