import React from 'react'
import { List, Datagrid, TextField, FunctionField, DateField, Filter, DateInput, TextInput } from 'react-admin'

import FormattedField from '../common/Fields/FormattedField'
import PersonDocumentField from '../common/Fields/PersonDocumentField'
import WalletUserLinkField from '../common/Fields/WalletUserLinkField'
import CommonListActions from '../common/RA-Overrides/CommonListActions'

const SearchFilters: React.FunctionComponent = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <TextInput source="document" alwaysOn />
    <DateInput source="dateBegin" alwaysOn />
    <DateInput source="dateEnd" alwaysOn />
  </Filter>
)

const ListTransactionsTriage: React.FunctionComponent = (props) => (
  <List
    {...props}
    actions={<CommonListActions />}
    bulkActionButtons={false}
    filters={<SearchFilters />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid>
      <DateField source="createdAt" showTime />
      <WalletUserLinkField source="name" documentSource="document" />
      <PersonDocumentField source="document" />
      <FunctionField source="amount" render={FormattedField.CurrencyBrl} />
      <FunctionField source="walletBank" render={FormattedField.WalletBank} />
      <TextField source="branch" />
      <TextField source="account" />
    </Datagrid>
  </List>
)

export default ListTransactionsTriage
