import React from 'react'
import { BooleanInput, Create, SimpleForm, TextInput } from 'react-admin'

const CreateWalletTransactionReason: React.FunctionComponent = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="active" defaultValue={true} />
    </SimpleForm>
  </Create>
)

export default CreateWalletTransactionReason
