import { createTheme } from '@material-ui/core/styles'

const brandRed = '#F32735'
const gray = '#2E2E2B'

export default createTheme({
  palette: {
    type: 'light',
    primary: {
      main: brandRed,
    },
    secondary: {
      main: gray,
    },
  },
})
