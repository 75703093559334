import React from 'react'
import {
  Create,
  SaveButton,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'

import { Choices, Entities } from '../../config'

const CreateNotifications: React.FunctionComponent = (props) => {
  const translate = useTranslate()

  const CreateToolbar = (props: ToolbarProps) => (
    <Toolbar {...props}>
      <SaveButton
        submitOnEnter={false}
        invalid={false}
        handleSubmitWithRedirect={async () => {
          if (!confirm(translate(`resources.${Entities.WALLET_NOTIFICATION}.confirm_notification_message`))) return
          props.handleSubmitWithRedirect?.()
        }}
      />
    </Toolbar>
  )

  return (
    <Create {...props}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="message" required />
        <SelectArrayInput label="Personas" source="personas" choices={Choices.PersonaTypes} required />
      </SimpleForm>
    </Create>
  )
}
export default CreateNotifications
