import { filter, get, map } from 'lodash'
import { AuthProvider, UserIdentity } from 'react-admin'
import { BackendRoutes } from '../config'
import { httpRequest } from '../networking'

/**
 * Efetiva o logout do usuário, removendo dados de acesso e tokens de sessão.
 */
const clearUserData = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('userPermissions')
}

/**
 * Provedor de autenticação da aplicação. Segue a API do react-admin.
 * Documentação: https://marmelab.com/react-admin/Authentication.html
 */
const authProvider: AuthProvider = {
  login: async ({ username: email, password }) => {
    const payload = { email, password }
    const options = { ignoreAccessToken: true, ignoreRefreshTokenFlux: true }
    const response = await httpRequest({
      verb: 'POST',
      path: BackendRoutes.routes.auth.LOGIN,
      data: payload,
      options,
    })
    localStorage.setItem('user', JSON.stringify(get(response, 'data.user')))
    localStorage.setItem('accessToken', get(response, 'data.accessToken'))
    localStorage.setItem(
      'userPermissions',
      JSON.stringify(
        // Retorna um array com todas as permissões ativas
        map(filter(get(response, 'data.permissions'), 'permission.active'), 'permission.slug')
      )
    )
    return Promise.resolve()
  },
  logout: () => {
    clearUserData()
    return Promise.resolve()
  },
  // Invocado quando a API retorna um erro qualquer
  checkError: ({ response }) => {
    // Verifica se a autorização do usuário expirou
    if (response.status === 401) {
      clearUserData()
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // Invocado quando o usuário navega para uma nova página
  checkAuth: () => {
    // Verifica se a autenticação continua válida
    return localStorage.getItem('user') ? Promise.resolve() : Promise.reject()
  },
  getIdentity: async () => {
    const user: any = JSON.parse(localStorage.getItem('user') || 'null')
    return { id: user?.id, fullName: user?.name } as UserIdentity
  },
  // Invocado quando o usuário navega para uma nova página
  // Permite verificar permissões de acesso baseadas em cargos (RBAC)
  getPermissions: () => {
    const permissions = JSON.parse(localStorage.getItem('userPermissions') ?? '[]')
    return Promise.resolve(permissions)
  },
}

export default authProvider
