import React from 'react'
import { Button, ButtonProps } from 'react-admin'
import { LockOpen as UnlockIcon } from '@material-ui/icons'
import { useAnonymizedContext } from '../Context/AnonymizedDataContext'

const TurnOffAnonymizationButton = (props: ButtonProps): React.ReactElement => {
  const { isAnonymized, toggleAnonymization } = useAnonymizedContext()

  return (
    <Button {...props} disabled={!isAnonymized} label="actions.viewRawData" onClick={() => toggleAnonymization()}>
      <UnlockIcon />
    </Button>
  )
}

export default TurnOffAnonymizationButton
