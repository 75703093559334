import polyglotI18nProvider from 'ra-i18n-polyglot'
import ptBrMessages from 'ra-language-pt-br'
import { merge } from 'lodash'

import commonMessages from '../i18n/common'
import domainMessages from '../i18n/domain'
import overrideMessages from '../i18n/overrides'

const messages = merge(ptBrMessages, overrideMessages, commonMessages, domainMessages)

export default polyglotI18nProvider(() => messages, 'pt-br')
