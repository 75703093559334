import { filter, isString } from 'lodash'
import { usePermissions } from 'react-admin'

/**
 * @returns true se o usuário logado possui todas as permissões informadas.
 */
export const userHasPermission = (...permissionsToCheck: (string | undefined)[]): boolean => {
  const { permissions: userPermissions = [] } = usePermissions()
  const validPermissions: string[] = filter(permissionsToCheck, isString)
  if (validPermissions.length === 0) return true
  return validPermissions.every((perm) => userPermissions.includes(perm))
}

/**
 * @returns true se o usuário logado possui qualquer uma das permissões informadas.
 */
export const userHasAnyPermission = (...permissionsToCheck: (string | undefined)[]): boolean => {
  const { permissions: userPermissions = [] } = usePermissions()
  const validPermissions: string[] = filter(permissionsToCheck, isString)
  if (validPermissions.length === 0) return true
  return validPermissions.some((perm) => userPermissions.includes(perm))
}
