import jwt from 'jsonwebtoken'

export interface GamersBankJwt {
  id: number
  iat: number
  exp: number
}

export const jwtIsValid = (): boolean => {
  const token = localStorage.getItem('accessToken')
  if (!token) {
    return false
  }

  const decoded = jwt.decode(token) as GamersBankJwt
  return decoded.exp * 1000 > new Date().valueOf()
}
