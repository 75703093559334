import React from 'react'
import { FormTabProps, Title, useRecordContext } from 'react-admin'
import { isNil } from 'lodash'

import { PersonType } from '../../../config/Types'
import { Entities } from '../../../config'
import PermissionConst from '../../../config/Permissions'
import { userHasPermission } from '../../../utils/Permission'
import { useAnonymizedContext } from '../../common/Context/AnonymizedDataContext'
import { Methods } from '../../../providers/dataProvider'
import useGetOneWithPayload from '../../common/RA-Overrides/useGetOneWithPayload'
import FormTab from '../../common/RA-Overrides/FormTab'

import SectionNaturalPerson from './SectionNaturalPerson'
import SectionJuridicalPerson from './SectionJuridicalPerson'
import SectionContacts from './SectionContacts'
import SectionAddress from './SectionAddress'

const TabUserDetails = (props: Omit<FormTabProps, 'label'>): JSX.Element => {
  // Neste contexto o record é referente aos dados do cliente
  const record = useRecordContext(props)
  // Não suportamos que o usuário anonimize os dados após mostrá-los
  const { isAnonymized } = useAnonymizedContext(PermissionConst[Entities.WALLET_USERS].TURN_OFF_ANONYMIZATION)
  useGetOneWithPayload(Entities.WALLET_USERS, record.id, { turnOffAnonymization: true }, { enabled: !isAnonymized })

  const personType = isNil(record.userDetailsCompany) ? PersonType.NATURAL_PERSON : PersonType.JURIDICAL_PERSON
  const userCanEdit = userHasPermission(PermissionConst[Entities.WALLET_USERS][Methods.update])

  return (
    <FormTab {...props} label={`resources.${Entities.WALLET_USERS}.tabs.clientDetails`}>
      <Title title={`: ${record.userDetailWallet?.fullName}`} />
      {personType === PersonType.JURIDICAL_PERSON && <SectionJuridicalPerson />}
      <SectionNaturalPerson />
      <SectionContacts disabled={!userCanEdit} />
      <SectionAddress disabled={!userCanEdit} />
    </FormTab>
  )
}

export default TabUserDetails
