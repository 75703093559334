import React from 'react'
import { Tooltip } from '@material-ui/core'
import { BooleanFieldProps, useRecordContext, useTranslate } from 'react-admin'
import CheckedIcon from '@material-ui/icons/Check'
import UnCheckedIcon from '@material-ui/icons/Close'
import { get, isNil } from 'lodash'

interface NullableBooleanFieldProps extends BooleanFieldProps {
  source: string
}

const NullableBooleanField = (props: NullableBooleanFieldProps): JSX.Element => {
  const translate = useTranslate()
  const record = useRecordContext(props)
  const value = get(record, props.source)

  if (isNil(value)) {
    return (
      <React.Fragment>
        <Tooltip title={props.valueLabelTrue ? translate(props.valueLabelTrue) : translate('ra.boolean.true')}>
          <CheckedIcon />
        </Tooltip>
      </React.Fragment>
    )
  }

  return (
    <Tooltip title={props.valueLabelFalse ? translate(props.valueLabelFalse) : translate('ra.boolean.false')}>
      <UnCheckedIcon />
    </Tooltip>
  )
}

export default NullableBooleanField
