import get from 'lodash/get'
import { Identifier, UseDataProviderOptions, useQueryWithStore, ReduxState, Refetch } from 'react-admin'

const useGetOneWithPayload = <RecordType>(
  resource: string,
  id: Identifier,
  payload: Record<string, any>,
  options?: UseDataProviderOptions
): UseGetOneHookValue<RecordType> =>
  useQueryWithStore({ type: 'getOne', resource, payload: { id, ...payload } }, options, (state: ReduxState) => {
    if (
      // resources are registered
      Object.keys(state.admin.resources).length > 0 &&
      // no registered resource matching the query
      !state.admin.resources[resource]
    ) {
      throw new Error(
        `No <Resource> defined for "${resource}". useGetOne() relies on the Redux store, so it cannot work if you don't include a <Resource>.`
      )
    }
    return get(state, ['admin', 'resources', resource, 'data', id])
  })

export type UseGetOneHookValue<RecordType> = {
  data?: RecordType
  loading: boolean
  loaded: boolean
  error?: any
  refetch: Refetch
}

export default useGetOneWithPayload
