import React from 'react'
import { get, isNil, isString } from 'lodash'
import { useRecordContext } from 'react-admin'
import { BusinessOutlined as BusinessIcon, PersonOutline as PersonIcon } from '@material-ui/icons'
import { Box } from '@material-ui/core'

import FieldProps from './FieldProps'
import Formatter from '../../../utils/Formatter'
import { PersonType } from '../../../config/Types'

const renderIcon = (personType?: PersonType): React.ReactNode => {
  if (personType === PersonType.JURIDICAL_PERSON) return <BusinessIcon fontSize="small" />
  if (personType === PersonType.NATURAL_PERSON) return <PersonIcon fontSize="small" />
  return null
}

const inferPersonType = (document: string): PersonType | undefined => {
  if (document.length === 14) return PersonType.JURIDICAL_PERSON
  if (document.length === 11) return PersonType.NATURAL_PERSON
}

interface PersonDocumentProps extends FieldProps {
  // A propriedade "source" não foi omitida intencionalmente: ela serve para fins de i18n.
  naturalPersonSource?: string
  juridicalPersonSource?: string
}

const PersonDocumentField = (props: PersonDocumentProps): JSX.Element => {
  const record = useRecordContext(props)
  const { naturalPersonSource = 'userDetailWallet', juridicalPersonSource = 'userDetailsCompany' } = props
  const naturalPersonData = get(record, naturalPersonSource)
  const juridicalPersonData = get(record, juridicalPersonSource)

  let personType
  let document = get(record, props.source)

  if (naturalPersonData || juridicalPersonData) {
    personType = isNil(juridicalPersonData) ? PersonType.NATURAL_PERSON : PersonType.JURIDICAL_PERSON
    document = juridicalPersonData?.cnpj || naturalPersonData?.cpf
  } else if (isString(document)) {
    personType = inferPersonType(document)
  }

  // Não foi possível inferir o tipo de pessoa ou documento
  if (!personType && !document) return <React.Fragment />

  return (
    <Box
      component="span"
      sx={{ display: 'inline-grid', gridTemplateColumns: '1fr auto', alignItems: 'center', gridGap: 2 }}
    >
      {renderIcon(personType)}
      {Formatter.brazilPersonDocument(document)}
    </Box>
  )
}

export default PersonDocumentField
