import React from 'react'
import { Resource, ResourceProps } from 'react-admin'

import { Methods } from '../../../providers/dataProvider'
import { resolvePermission } from '../../../config/Permissions'
import { userHasPermission } from '../../../utils/Permission'

const ShieldedResource: React.FunctionComponent<ResourceProps> = (props) => {
  const { create, edit, list, show, ...rest } = props
  const resourceProps: ResourceProps = { ...rest }

  const [userHasCreatePermission, userHasEditPermission, userHasListPermission, userHasViewPermission] = [
    Methods.create,
    Methods.update,
    Methods.getList,
    Methods.getOne,
  ].map((method) => userHasPermission(resolvePermission(props.name, method)))

  if (userHasCreatePermission && create) {
    resourceProps.create = create
  }

  if (userHasEditPermission && edit) {
    resourceProps.edit = edit
  }

  if (userHasListPermission && list) {
    resourceProps.list = list
  }

  if (userHasViewPermission && show) {
    resourceProps.show = show
  }

  return <Resource {...resourceProps} />
}

export default ShieldedResource
