import React from 'react'
import { FieldProps, Link, TextField, useRecordContext } from 'react-admin'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/core'

import { Entities } from '../../../config'

interface IdSourceProps {
  idSource: string
  documentSource?: never
}

interface DocumentSourceProps {
  idSource?: never
  documentSource: string
}

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
  { name: 'RaLink' }
)

const WalletUserLinkField = (props: FieldProps & (IdSourceProps | DocumentSourceProps)) => {
  const { idSource, documentSource, ...restProps } = props
  const record = useRecordContext(props)
  const classes = useStyles()

  if (!record) return null

  let link

  if (idSource) {
    link = `/${Entities.WALLET_USERS}/${get(record, idSource)}`
  } else if (documentSource) {
    link = `/${Entities.WALLET_USERS}?filter=${JSON.stringify({ document: get(record, documentSource) })}`
  }

  return (
    <Link to={link}>
      <TextField className={classes.link} {...restProps} />
    </Link>
  )
}

WalletUserLinkField.defaultProps = {
  addLabel: true,
}

export default WalletUserLinkField
