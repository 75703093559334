import React from 'react'
import { composeValidators, InputProps, TextInput, TextInputProps, useTranslate } from 'react-admin'

import { onlyNumbers } from '../../../utils/Formatter'
import MaskedInputWrapper from './MaskedInputWrapper'

const phoneMask = [
  // DDI com +
  '+',
  /[1-9]/,
  /[1-9]/,
  ' ',
  // DDD e espaço
  '(',
  /[1-9]/,
  /[1-9]/,
  ')',
  ' ',
  // 9 dígitos com hífen
  /[2-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
]

const inputProps: Partial<InputProps> = { inputComponent: MaskedInputWrapper }

/**
 * Certifica que o valor do telefone, após remoção da formatação tem apenas 13 digitos.
 */
const phoneValidator =
  (message = 'validation.phoneNumber.length') =>
  (value: string) =>
    value && onlyNumbers(value).length === 13 ? undefined : message

const PhoneInput = (props: TextInputProps): JSX.Element => {
  const translate = useTranslate()
  return (
    <TextInput
      {...props}
      label={translate(`resources.${props.resource}.fields.${props.source}`)}
      validate={composeValidators(phoneValidator(), props.validate)}
      InputProps={inputProps}
      inputProps={{ mask: phoneMask }}
      InputLabelProps={{ shrink: true }}
    />
  )
}

export default PhoneInput
