import { get } from 'lodash'

import { Entities } from '.'
import { Methods } from '../providers/dataProvider'

const PermissionConst = {
  [Entities.WALLET_USER_ACCOUNTS]: {
    [Methods.getList]: 'get/wallet/user-account',
  },
  [Entities.WALLET_USERS]: {
    [Methods.getList]: 'get/wallet/users-list',
    [Methods.update]: 'put/wallet/user/:userId',
    TURN_OFF_ANONYMIZATION: 'get/user/turn-off-anonymization',
  },
  [Entities.WALLETS]: {
    [Methods.getList]: 'get/wallets',
    TURN_OFF_ANONYMIZATION: 'get/wallet/turn-off-anonymization',
  },
  [Entities.WALLET_TRANSACTIONS]: {
    [Methods.getList]: 'get/wallet/user/transactions',
  },
  [Entities.WALLET_TRANSACTIONS]: {
    [Methods.getList]: 'get/wallet/user/transactions',
  },
  [Entities.WALLET_SOLICITATIONS]: {
    [Methods.getList]: 'get/wallet/solicitations',
  },
  [Entities.WALLET_BALANCE]: {
    [Methods.getList]: 'get/wallet/balances',
  },
  [Entities.TRANSACTIONS]: {
    [Methods.getList]: 'get/bankly-events',
  },
  [Entities.MY_PROFILE]: {
    [Methods.getOne]: 'get/back-office-user/:id',
  },
  [Entities.BO_USER_GROUP]: {
    [Methods.getList]: 'get/back-office-groups',
    [Methods.create]: 'post/back-office-group',
    [Methods.update]: 'put/back-office-group/:id',
    [Methods.getOne]: 'get/back-office-group/:id',
  },
  [Entities.TRANSACTIONS_TRIAGE]: {
    [Methods.getList]: 'get/wallet-solicitation-batch-event/triages',
  },
  [Entities.WALLET_TRANSACTION_REASONS]: {
    [Methods.getList]: 'get/transaction-wallet-reason',
    [Methods.update]: 'put/transaction-wallet-reason/:userId',
    [Methods.create]: 'post/transaction-wallet-reason',
  },
  [Entities.WALLET_NOTIFICATION]: {
    [Methods.getList]: 'get/wallet-notification-broadcast',
    [Methods.create]: 'post/notificate',
  },
  [Entities.WALLET_PENDING_EXCHANGES]: {
    //[Methods.getList]: 'get/pending-chip-money-exchanges',
    //[Methods.update]: 'put/pending-chip-money-exchanges/:id/approve',
  },
}

export const resolvePermission = (entity: string, method: Methods): string | undefined =>
  get(PermissionConst, [entity, method])

export default PermissionConst
