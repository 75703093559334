import React, { useEffect } from 'react'
import { ResourceComponentPropsWithId, Show, TabbedForm, useRedirect } from 'react-admin'
import TabUserAccounts from './TabUserAccounts'

import { Entities } from '../../config'
import { Methods } from '../../providers/dataProvider'
import PermissionConst from '../../config/Permissions'
import { userHasPermission } from '../../utils/Permission'

import { Tabs } from './Tabs'
import TabUserDetails from './TabUserDetails'
import TabUserSolicitations from './TabUserSolicitations'
import TabUserTransactions from './TabUserTransactions'
import TabWalletDetails from './TabWalletDetails'

const WalletUserShow = (props: ResourceComponentPropsWithId): React.ReactElement => {
  const redirect = useRedirect()

  const showWalletDetails = userHasPermission(PermissionConst[Entities.WALLETS][Methods.getList])
  const showUserDetails = userHasPermission(PermissionConst[Entities.WALLET_USERS][Methods.getList])
  const showTransactions = userHasPermission(PermissionConst[Entities.WALLET_TRANSACTIONS][Methods.getList])
  const showSolicitations = userHasPermission(PermissionConst[Entities.WALLET_SOLICITATIONS][Methods.getList])
  const showAccounts = userHasPermission(PermissionConst[Entities.WALLET_USER_ACCOUNTS][Methods.getList])

  useEffect(() => {
    // Garante que uma aba será selecionada na primeira renderização da tela
    redirect(() => `${props.basePath}/${props.id}/show/${Tabs.UserDetails}`)
  }, [])

  return (
    <Show {...props}>
      <TabbedForm toolbar={<React.Fragment />}>
        {showUserDetails && <TabUserDetails path={Tabs.UserDetails} />}
        {showWalletDetails && <TabWalletDetails path={Tabs.Wallets} />}
        {showTransactions && <TabUserTransactions path={Tabs.Transactions} />}
        {showSolicitations && <TabUserSolicitations path={Tabs.Solicitations} />}
        {showAccounts && <TabUserAccounts path={Tabs.Accounts} />}
      </TabbedForm>
    </Show>
  )
}

export default WalletUserShow
