import { Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import { Button, Confirm } from 'react-admin'

interface IConfirmModalProps {
  icon: React.ReactElement
  handleConfirm: () => void
  title: string
  description: string
  tooltip: string
}

const ConfirmModal = ({ title, description, tooltip, icon, handleConfirm }: IConfirmModalProps): JSX.Element | null => {
  const [open, setOpen] = useState(false)

  const onConfirm = () => {
    handleConfirm()
    setOpen(false)
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <Tooltip title={tooltip}>{icon}</Tooltip>
      </Button>

      <Confirm isOpen={open} title={title} content={description} onConfirm={onConfirm} onClose={() => setOpen(false)} />
    </>
  )
}

export default ConfirmModal
