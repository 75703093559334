import React from 'react'
import { useListContext, Button } from 'react-admin'
import { Clear as ClearIcon } from '@material-ui/icons'
import { isEmpty } from 'lodash'

const ClearFiltersButton = (): React.ReactElement => {
  const { filterValues, setFilters } = useListContext()
  if (isEmpty(filterValues)) return <React.Fragment />
  return (
    <Button label="actions.clearFilters" onClick={() => setFilters({}, null)}>
      <ClearIcon />
    </Button>
  )
}

export default ClearFiltersButton
