import React from 'react'
import { isNil } from 'lodash'
import { Tooltip } from '@material-ui/core'

interface IconWithHintProps {
  id: number
  hint: string
  iconResolver: (value: any) => JSX.Element | undefined
}

const IconWithHint = (props: IconWithHintProps): JSX.Element | null => {
  const icon = props.iconResolver(props.id)
  return isNil(icon) ? null : <Tooltip title={props.hint}>{icon}</Tooltip>
}

export default IconWithHint
