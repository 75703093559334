import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { TextInput, useTranslate } from 'react-admin'

import { Entities } from '../../../config'
import CnpjInput from '../../common/Inputs/CnpjInput'

const SectionJuridicalPerson = (): React.ReactElement => {
  const translate = useTranslate()
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {translate(`resources.${Entities.WALLET_USERS}.sections.juridicalPerson`)}
      </Typography>
      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <CnpjInput disabled source="userDetailsCompany.cnpj" resource={Entities.WALLET_USERS} fullWidth />
        </Box>
        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput disabled source="userDetailsCompany.corporateName" resource={Entities.WALLET_USERS} fullWidth />
        </Box>
        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput disabled source="userDetailsCompany.tradingName" resource={Entities.WALLET_USERS} fullWidth />
        </Box>
      </Box>
    </Box>
  )
}

export default SectionJuridicalPerson
