import { makeStyles } from '@material-ui/core'
import { get } from 'lodash'
import React from 'react'
import { FunctionField, FunctionFieldProps, useRecordContext } from 'react-admin'
import { Currency, TransactionType } from '../../../config/Types'
import FormattedField from './FormattedField'

const useStyles = makeStyles({
  cashIn: {
    color: '#357a38',
  },
  cashOut: {
    color: '#f44336',
  },
})

interface MonetaryFieldProps extends Omit<FunctionFieldProps, 'render'> {
  colored?: boolean
  source: string
  transactionTypeIdSource?: string
  currencyTypeIdSource?: Currency | string
}

/**
 * Renderiza o campo como valor monetário.
 */
const MonetaryField = (props: MonetaryFieldProps): React.ReactElement => {
  const record = useRecordContext()
  const classes = useStyles()
  const { colored, currencyTypeIdSource, transactionTypeIdSource, ...rest } = props
  const { [props.source]: value } = record
  const currency = currencyTypeIdSource ? currencyTypeIdSource : Currency.BRL
  const transactionType = transactionTypeIdSource ? get(record, transactionTypeIdSource) : undefined

  let className
  if (colored && value) {
    if (transactionType) {
      className = transactionType === TransactionType.CASH_IN ? classes.cashIn : classes.cashOut
    } else {
      className = value > 0 ? classes.cashIn : classes.cashOut
    }
  }

  let formatter = FormattedField.CurrencyBrl
  if (currency === Currency.CHIPS) formatter = FormattedField.CurrencyChips
  else if (currency === Currency.GAMERSCOINS) formatter = FormattedField.CurrencyCoins

  return <FunctionField {...rest} className={className} render={formatter} />
}

export default MonetaryField
