import { Entities } from '../config'

import {
  Currency,
  PersonType,
  TransactionType,
  BanklyTransactionStatus,
  Gender,
  TransactionTriageStatus,
  AccountType,
  BrazilUf,
  TransactionStatus,
  WalletStatus,
  PersonaTypes,
} from '../config/Types'
/**
 * Representa as traduções específicas para o domínio da aplicação (regras de negócio).
 */
export default {
  // Tipos relacionáveis
  types: {
    BrazilUf: {
      [BrazilUf.AC]: 'Acre',
      [BrazilUf.AL]: 'Alagoas',
      [BrazilUf.AP]: 'Amapá',
      [BrazilUf.AM]: 'Amazonas',
      [BrazilUf.BA]: 'Bahia',
      [BrazilUf.CE]: 'Ceará',
      [BrazilUf.DF]: 'Distrito Federal',
      [BrazilUf.ES]: 'Espírito Santo',
      [BrazilUf.GO]: 'Goiás',
      [BrazilUf.MA]: 'Maranhão',
      [BrazilUf.MT]: 'Mato Grosso',
      [BrazilUf.MS]: 'Mato Grosso do Sul',
      [BrazilUf.MG]: 'Minas Gerais',
      [BrazilUf.PA]: 'Pará',
      [BrazilUf.PB]: 'Paraíba',
      [BrazilUf.PR]: 'Paraná',
      [BrazilUf.PE]: 'Pernambuco',
      [BrazilUf.PI]: 'Piauí',
      [BrazilUf.RJ]: 'Rio de Janeiro',
      [BrazilUf.RN]: 'Rio Grande do Norte',
      [BrazilUf.RS]: 'Rio Grande do Sul',
      [BrazilUf.RO]: 'Rondônia',
      [BrazilUf.RR]: 'Roraima',
      [BrazilUf.SC]: 'Santa Catarina',
      [BrazilUf.SP]: 'São Paulo',
      [BrazilUf.SE]: 'Sergipe',
      [BrazilUf.TO]: 'Tocantins',
    },
    Currencies: {
      [Currency.CHIPS]: 'Ficha |||| Fichas',
      [Currency.BRL]: 'Real |||| Reais',
      [Currency.GAMERSCOINS]: 'GamersCoins',
    },
    Gender: {
      [Gender.MALE]: 'Masculino',
      [Gender.FEMALE]: 'Feminino',
      [Gender.OTHER]: 'Outro',
    },
    TransactionType: {
      [TransactionType.CASH_IN]: 'Depósito',
      [TransactionType.CASH_OUT]: 'Saque',
    },
    PersonType: {
      [PersonType.NATURAL_PERSON]: 'Pessoa Física',
      [PersonType.JURIDICAL_PERSON]: 'Pessoa Jurídica',
    },
    BanklyTransactionStatus: {
      [BanklyTransactionStatus.ACTIVE]: 'Ativo',
      [BanklyTransactionStatus.CANCELLED]: 'Cancelado',
    },
    TransactionStatus: {
      [TransactionStatus.APPROVED]: 'Aprovada',
      [TransactionStatus.IN_PROCESS]: 'Pendente',
    },
    TransactionTriageStatus: {
      [TransactionTriageStatus.PENDING]: 'Pendente',
      [TransactionTriageStatus.DENIED]: 'Negada',
    },
    AccountTypes: {
      [AccountType.CHECKING]: 'Conta corrente',
      [AccountType.NULL]: '',
    },
    WalletStatus: {
      [WalletStatus.ACTIVE]: 'Ativo',
      [WalletStatus.BLOCKED]: 'Bloqueado',
      [WalletStatus.SUSPECTED_FRAUD]: 'Suspeito de fraude',
    },
    PersonaTypes: {
      [PersonaTypes.ORGANIC]: 'Orgânico',
      [PersonaTypes.AFILIATE]: 'Afiliado',
      [PersonaTypes.PLAYER]: 'Jogador',
      [PersonaTypes.PROMOTER_PF]: 'Promotor Pessoa Física',
      [PersonaTypes.TEAM_PF]: 'Time Pessoa Física',
      [PersonaTypes.PROMOTER_PJ]: 'Promotor Pessoa Jurídica',
      [PersonaTypes.TEAM_PJ]: 'Time Pessoa Jurídica',
      [PersonaTypes.COMERCIAL]: 'Comercial',
    },
  },
  // Entidades
  resources: {
    [Entities.BO_USER_GROUP]: {
      name: 'Grupo |||| Grupos',
      fields: {
        id: 'ID',
        name: 'Nome',
        active: 'Ativo',
        createdAt: 'Criação',
        updatedAt: 'Última alteração',
      },
    },
    [Entities.TRANSACTIONS]: {
      name: 'Conta Acesso |||| Conta Acesso',
      fields: {
        timestamp: 'Data e hora',
        'transactionWalletType.id': 'Tipo de transação',
        status: 'Status',
        name: 'Nome',
        document: 'Documento',
        walletBank: 'Banco',
        branch: 'Agência',
        account: 'Conta',
        amount: 'Valor',
        currentBalance: 'Saldo atual',
        dateBegin: 'Data inicial',
        dateEnd: 'Data final',
        cnpj: 'CNPJ',
        balance: 'Saldo atual',
      },
    },
    [Entities.TRANSACTIONS_TRIAGE]: {
      name: 'Triagem de Transação |||| Triagem de Transações',
      fields: {
        name: 'Nome do Cliente',
        document: 'Documento do Cliente',
        amount: 'Valor',
        createdAt: 'Data e Hora',
        walletBank: 'Banco',
        branch: 'Agência',
        account: 'Conta',
        'transactionWalletSolicitation.denyReason': 'Motivo',
        status: 'Status',
        dateBegin: 'Data inicial',
        dateEnd: 'Data final',
      },
    },
    [Entities.WALLET_USERS]: {
      name: 'Cliente |||| Clientes',
      fields: {
        id: 'ID',
        document: 'Documento',
        email: 'E-mail',
        personType: 'Tipo de Cadastro',
        userWallets: 'Wallets',
        userDetailWallet: {
          cpf: 'CPF',
          fullName: 'Nome completo',
          phoneNumber: 'Telefone',
          birthDate: 'Nascimento',
          gender: 'Gênero',
          address: 'Endereço',
          addressCity: 'Cidade',
          addressNeighborhood: 'Bairro',
          addressNumber: 'Número',
          addressState: 'Estado',
          addressZipCode: 'CEP',
          addressComplement: 'Complemento',
          addressZipCode: 'CEP',
        },
        userDetailsCompany: {
          cnpj: 'CNPJ',
          corporateName: 'Razão Social',
          tradingName: 'Nome Fantasia',
          openingDate: 'Data de Abertura',
        },
      },
      tabs: {
        clientDetails: 'Dados Pessoais',
        walletDetails: 'Dados da Wallet',
      },
      sections: {
        naturalPerson: 'Pessoa Física',
        juridicalPerson: 'Pessoa Jurídica',
        contacts: 'Contatos',
        address: 'Endereço',
      },
    },
    [Entities.WALLET_SOLICITATIONS]: {
      name: 'Últimas movimentações',
      fields: {
        createdAt: 'Data e Hora',
        value: 'Valor',
        'transactionWalletSubType.name': 'Operação',
        'transactionWalletSolicitationStatus.name': 'Status',
        'userWalletAccount.walletBank': 'Banco',
      },
    },
    [Entities.WALLET_USER_ACCOUNTS]: {
      name: 'Contas Bancárias',
      fields: {
        document: 'Documento',
        branch: 'Agência',
        number: 'Conta',
        isDefaultAccount: 'Conta Padrão',
        pixKey: 'Chave PIX',
        deletedAt: 'Status',
        'walletBank.shortName': 'Banco',
        'walletBank.code': 'Código',
      },
      status: {
        active: 'Ativo',
        inactive: 'Deletado',
      },
      filters: {
        status: 'Status da Conta',
      },
    },
    [Entities.WALLET_TRANSACTIONS]: {
      name: 'Últimas transações',
      fields: {
        createdAt: 'Data e Hora',
        value: 'Valor',
        description: 'Descrição',
        'walletCoin.name': 'Moeda',
        'transactionSubType.name': 'Operação',
        'account.companyName': 'Parceiro',
      },
      filters: {
        walletCoin: 'Extrato de',
        transactionWalletStatus: 'Situação',
      },
    },
    [Entities.WALLET_TRANSACTION_REASONS]: {
      name: 'Motivos de Rejeição de Transações',
      fields: {
        name: 'Motivo',
        active: 'Ativo',
      },
    },
    [Entities.WALLETS]: {
      name: 'Carteira |||| Carteiras',
      fields: {
        pan: 'Número da Wallet',
        expirationDate: 'Data de Validade',
        createdAt: 'Data da Criação',
        'status.name': 'Status',
        'status.createdAt': 'Data do Último Status',
        slug: 'IdWallet',
        personaWalletName: 'Perfil',
        'walletTeam.name': 'Time',
        walletSponsor: 'Sponsor',
        walletSponsorCode: 'Código',
        nicknames: 'Nicknames',
      },
      sections: {
        wallet: 'Wallet',
        sponsor: 'Sponsor',
        nicknames: 'Nicknames',
      },
    },
    [Entities.MY_PROFILE]: {
      name: 'Meus dados',
      fields: {
        name: 'Nome',
        email: 'E-mail',
        phone: 'Telefone',
        password: 'Senha',
        passwordConfirm: 'Confirmação da senha',
      },
    },
    [Entities.TRANSACTION_WALLET]: {
      name: 'Transações',
      fields: {
        createdAt: 'Data e hora',
        name: 'Nome do cliente',
        'account.companyName': 'Parceiro',
        pan: 'Wallet do usuário',
        'transactionSubType.name': 'Tipo de transação',
        value: 'Valor',
        status: 'Situação',
        account: 'Parceiro',
        dateBegin: 'Data inicial',
        dateEnd: 'Data final',
      },
    },
    [Entities.WALLET_BALANCE]: {
      name: 'Saldo da conta |||| Saldos das contas',
      fields: {
        account: 'Conta',
        balance: 'Saldo',
        createdAt: 'Data da Criação',
        updatedAt: 'Data de Atualização',
        'walletCoin.id': 'Id Coin',
        'walletCoin.name': 'Moeda',
      },
    },
    [Entities.WALLET_NOTIFICATION]: {
      name: 'Notificações Manuais',
      confirm_notification_message: 'Gostaria de criar essa notificação ?',
      fields: {
        createdAt: 'Data da Criação',
        'walletNotificationBroadcast.message': 'Mensagem',
        'walletNotificationBroadcast.backOfficeUserId': 'Usuário responsável',
        'walletPersona.name': 'Persona',
        dateBegin: 'Data inicial',
        dateEnd: 'Data final',
        message: 'Mensagem',
      },
    },
    [Entities.WALLET_PENDING_EXCHANGES]: {
      name: 'Mesa de Aprovação',
      confirm_notification_message: 'Gostaria de criar essa notificação ?',
      fields: {
        id: 'Ações',
        createdAt: 'Data da Criação',
        'walletNotificationBroadcast.message': 'Mensagem',
        dateBegin: 'Data inicial',
        dateEnd: 'Data final',
        fullName: 'Nome do usuário',
        team: 'Nome do time',
        originValue: 'Valor em ficha',
        destinyValue: 'Valor em real',
        denyReason: 'Motivo',
        message: 'Mensagem',
      },
    },
  },
  // Páginas
  pages: {
    welcome: {
      helpCardTitle: 'Dicas para usar o painel administrativo',
      tips: {
        filters: 'Ao filtrar resultados, não utilize formatação (como pontos e traços de documentos);',
        dates: 'A exibição de datas segue as configurações do seu dispositivo;',
      },
    },
  },
  // Validações
  validation: {
    cpf: {
      length: 'Utilize 11 dígitos',
    },
    cnpj: {
      length: 'Utilize 14 dígitos',
    },
    zipCode: {
      length: 'Utilize 8 dígitos',
    },
    phoneNumber: {
      length: 'Utilize 13 dígitos (DDI, DDD e número)',
    },
    match: {
      passwordDiff: 'Este campo deve corresponder ao campo de senha',
    },
  },
}
