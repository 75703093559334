import axios, { AxiosResponse } from 'axios'
import { jwtIsValid } from './jwt'
import BackEndRoutes from '../config/BackendRoutes'
import { stringify } from 'querystring'

export interface RequestOptions {
  ignoreAccessToken?: boolean
}

export type HttpVerb = 'POST' | 'PUT' | 'GET' | 'DELETE'

export type QueryParam = Record<string, string | number | boolean>

export interface HttpRequest {
  verb: HttpVerb
  path: string
  data?: unknown
  query?: QueryParam
  headers?: Headers
  options?: RequestOptions
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const httpRequest = async (request: HttpRequest): Promise<AxiosResponse> => {
  const { options } = request
  let accessToken = localStorage.getItem('accessToken')

  if (!options?.ignoreAccessToken && !accessToken) {
    throw new Error('No accessToken found')
  }

  if (!jwtIsValid() && accessToken) {
    accessToken = localStorage.getItem('accessToken')
  }

  const headers: Record<string, string> = {
    Authorization: (options?.ignoreAccessToken ? null : accessToken) ?? '',
  }

  // Add headers from request
  request.headers?.forEach((header, value) => {
    headers[header] = value
  })

  const finalQuery = { lang: 'br', ...request.query }

  const response = await axios({
    method: request.verb,
    baseURL: BackEndRoutes.host,
    url: `${request.path}?${stringify(finalQuery)}`,
    data: request.data,
    headers,
  })
  // TODO: handle errors
  return response.data
}
