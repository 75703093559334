/**
 * Traduções específicas para sobrescrever as mensagens disponibilizadas pela
 * biblioteca ra-language-pt-br.
 */
export default {
  ra: {
    page: {
      dashboard: 'Início',
    },
    notification: {
      updated: 'Atualizado com sucesso!',
    },
  },
}
