import React, { FC, useCallback, useContext, useState } from 'react'
import { forEach, isArray, isNil, isString, reduce, some } from 'lodash'

/** { permissionName: isAnonymized } */
type AnonymizationState = Record<string, boolean>

/**
 * Alterna (true->false, false->true) o estado de anonimização para a permissão informada - ou alterna todas.
 * @returns true se a permissão informada (ou todas) está anonimizada.
 */
export type ToggleAnonymizationFunction = (permission?: string) => boolean

interface AnonymizedModeContext {
  state: AnonymizationState
  toggle: ToggleAnonymizationFunction
}

interface UseAnonymizationResponse {
  isAnonymized: boolean
  toggleAnonymization: ToggleAnonymizationFunction
}

const AnonymizedModeContext = React.createContext<AnonymizedModeContext>({ state: {}, toggle: () => true })

interface AnonymizedContextProviderProps {
  permissions: string | string[]
}

/**
 * Provedor de contexto para permitir a desanonimização de dados em uma tela.
 * Note que o estado é local, logo, cada instância do AnonymizedContextProvider possui
 * seu próprio gerenciamento - assim o uso em uma tela não afetará as demais.
 */
export const AnonymizedContextProvider: FC<AnonymizedContextProviderProps> = ({ children, permissions }) => {
  // Por padrão, todos os dados estão anonimizados
  const initialState: AnonymizationState = {}
  if (isArray(permissions)) {
    forEach(permissions, (perm) => (initialState[perm] = true))
  } else if (isString(permissions)) {
    initialState[permissions] = true
  }

  const [state, setState] = useState<AnonymizationState>(initialState)

  const toggle = useCallback((permission?: string) => {
    let isAnonymized = true
    setState((prevState) => {
      // Atualiza apenas a permissão informada
      if (permission) {
        isAnonymized = !(prevState[permission] ?? true)
        return {
          ...prevState,
          [permission]: isAnonymized,
        }
      }
      // Atualiza todas as permissões
      isAnonymized = some(prevState, true)
      return reduce(
        prevState,
        (newState, _oldValue, permission) => {
          newState[permission] = isAnonymized
          return newState
        },
        {} as AnonymizationState
      )
    })
    return isAnonymized
  }, [])

  return <AnonymizedModeContext.Provider value={{ state, toggle }}>{children}</AnonymizedModeContext.Provider>
}

/**
 * Permite utilizar o contexto de permissões para determinar se uma, várias ou todas as permissões do contexto -
 * inicializadas na declaração do AnonymizedContextProvider - estão anonimizadas ou não.
 *
 * Componentes filhos (abaixo da árvore) do provedor devem consumir esse hook para determinar o estado de
 * anonimização das informações e recarregar os dados caso necessário.
 *
 * @returns varia de acordo com o parâmetro permissions:
 *
 * isAnonymized - indica se a anonimização está ativada, e o resultado varia de acordo com o parâmetro permissions
 * do hook:
 * - Se permissions = null, retorna true caso alguma das permissões esteja anonimizada;
 * - Se permissions = string, retorna true caso a permissão informada esteja anonimizada;
 * - Se permissions = Array<String>, retorna true caso uma das permissões informadas esteja anonimizada.
 *
 * toggleAnonymization - setter para alternar o modo de anonimização.
 * Seu comportamento varia de acordo com o parâmetro que ela recebe:
 * - Se invocada com null e alguma permissão do estado estiver anonimizada, todas serão desanonimizadas;
 * - Se invocada com null e as todas as permissões do estado estiverem desanonimizadas, todas serão anonimizadas;
 * - Se invocada com uma string, a permissão trocará de estado (true->false e false->true).
 */
export const useAnonymizedContext = (permissions?: string | string[]): UseAnonymizationResponse => {
  const { state, toggle } = useContext(AnonymizedModeContext)

  let isAnonymized = true
  if (isNil(permissions)) {
    isAnonymized = some(state) // verifica se qualquer um dos valores é true
  } else if (isString(permissions)) {
    isAnonymized = state[permissions] ?? true
  } else if (isArray(permissions)) {
    isAnonymized = some(permissions, (perm) => state[perm] ?? true)
  }

  return {
    isAnonymized,
    toggleAnonymization: toggle,
  }
}
