import React from 'react'
import { SelectInput, TextInput, useTranslate } from 'react-admin'
import { Box, Typography } from '@material-ui/core'

import { Entities } from '../../../config'
import Choices from '../../../config/Choices'
import CpfInput from '../../common/Inputs/CpfInput'

const SectionNaturalPerson = (): JSX.Element => {
  const translate = useTranslate()
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {translate(`resources.${Entities.WALLET_USERS}.sections.naturalPerson`)}
      </Typography>
      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <CpfInput disabled resource={Entities.WALLET_USERS} source="userDetailWallet.cpf" fullWidth />
        </Box>
        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput disabled resource={Entities.WALLET_USERS} source="userDetailWallet.fullName" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput disabled resource={Entities.WALLET_USERS} source="userDetailWallet.birthDate" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <SelectInput
            disabled
            resource={Entities.WALLET_USERS}
            source="userDetailWallet.gender"
            choices={Choices.Genders}
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  )
}

export default SectionNaturalPerson
