enum Entities {
  BO_USER_GROUP = 'backoffice-user-group',
  USER = 'user',
  USER_ADDRESS = 'userAddress',
  USER_PHONE = 'userPhone',
  TRANSACTIONS = 'transactions',
  TRANSACTIONS_TRIAGE = 'transactionsTriage',
  WALLET_USERS = 'wallet-users',
  WALLET_USER_ACCOUNTS = 'wallet-user-accounts',
  WALLET_SOLICITATIONS = 'walletSolicitations',
  WALLET_TRANSACTIONS = 'walletTransactions',
  WALLET_BALANCE = 'walletBalance',
  WALLET_TRANSACTION_REASONS = 'transactions-wallet-reason',
  ACCOUNTS = 'accounts',
  WALLETS = 'wallets',
  MY_PROFILE = 'my-profile',
  TRANSACTION_WALLET = 'transaction-wallet',
  WALLET_NOTIFICATION = 'notificationWallet',
  WALLET_PENDING_EXCHANGES = 'walletPendingExchanges',
}

export default Entities
