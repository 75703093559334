import React from 'react'
import { List, Datagrid, TextField, Filter, DateInput, DateField, TextInput } from 'react-admin'
import MonetaryField from '../common/Fields/MonetaryField'
import WalletUserLinkField from '../common/Fields/WalletUserLinkField'
import CommonListActions from '../common/RA-Overrides/CommonListActions'

const SearchFilters: React.FunctionComponent = (props) => (
  <Filter {...props}>
    <TextInput source="status" alwaysOn />
    <TextInput source="account" alwaysOn />
    <DateInput source="dateBegin" alwaysOn />
    <DateInput source="dateBegin" alwaysOn />
  </Filter>
)

const ListTransactionWallet: React.FunctionComponent = (props) => (
  <List {...props} bulkActionButtons={false} filters={<SearchFilters />} actions={<CommonListActions />}>
    <Datagrid>
      <DateField source="createdAt" showTime sortable={false} />
      <WalletUserLinkField source="name" idSource="userId" sortable={false} />
      <TextField source="account.companyName" sortable={false} />
      <TextField source="pan" sortable={false} />
      <TextField source="transactionSubType.name" sortable={false} />
      <MonetaryField source="value" colored sortable={false} />
      <TextField source="status" sortable={false} />
    </Datagrid>
  </List>
)

export default ListTransactionWallet
