import * as React from 'react'
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin'

const PostList: React.FunctionComponent = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <DateField source="updatedAt" sortable={false} />
    </Datagrid>
  </List>
)

export default PostList
