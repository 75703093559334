import React from 'react'
import { composeValidators, InputProps, TextInput, TextInputProps, useTranslate } from 'react-admin'

import MaskedInputWrapper from './MaskedInputWrapper'

const cpfMask = [
  /[0-9!*]/,
  /[0-9!*]/,
  /[0-9!*]/,
  '.',
  /[0-9!*]/,
  /[0-9!*]/,
  /[0-9!*]/,
  '.',
  /[0-9!*]/,
  /[0-9!*]/,
  /[0-9!*]/,
  '-',
  /[0-9!*]/,
  /[0-9!*]/,
]

const inputProps: Partial<InputProps> = { inputComponent: MaskedInputWrapper }

/**
 * Certifica que o valor, após remoção da formatação tem apenas 12 digitos.
 */
const cpfValidator =
  (message = 'validation.cpf.length') =>
  (value: string) =>
    value?.length === 11 ? undefined : message

const CpfInput = (props: TextInputProps): JSX.Element => {
  const translate = useTranslate()
  return (
    <TextInput
      {...props}
      label={translate(`resources.${props.resource}.fields.${props.source}`)}
      validate={composeValidators(cpfValidator(), props.validate)}
      InputProps={inputProps}
      inputProps={{ mask: cpfMask }}
      InputLabelProps={{ shrink: true }}
    />
  )
}

export default CpfInput
