/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'
import { UserMenu, MenuItemLink, UserMenuProps, useTranslate } from 'react-admin'
import SettingsIcon from '@material-ui/icons/Settings'
import { Entities } from '../../../config'

const MyUserMenu = (props: UserMenuProps): JSX.Element => {
  const translate = useTranslate()
  return (
    <UserMenu {...props}>
      {/* @ts-ignore */}
      <MenuItemLink
        to={`/${Entities.MY_PROFILE}/edit`}
        primaryText={translate(`resources.${Entities.MY_PROFILE}.name`)}
        leftIcon={<SettingsIcon />}
      />
    </UserMenu>
  )
}

export default MyUserMenu
