import { get } from 'lodash'
import { Currency } from '../../../config/Types'
import Formatter from '../../../utils/Formatter'

type FormatterFunction = (value: any) => string
type RenderFunction = (record?: any, source?: string) => string | null

export const buildFormattedField = (formatter: FormatterFunction): RenderFunction => {
  return (record?: any, source?: string) => {
    if (!record || !source) return null
    const value = get(record, source, null)
    return value ? formatter(value) : null
  }
}

export default {
  CurrencyBrl: buildFormattedField((amount) => Formatter.currency(Currency.BRL, amount)),
  CurrencyChips: buildFormattedField((amount) => Formatter.currency(Currency.CHIPS, amount)),
  CurrencyCoins: buildFormattedField((amount) => Formatter.currency(Currency.GAMERSCOINS, amount)),
  PersonDocument: buildFormattedField(Formatter.brazilPersonDocument),
  PhoneNumber: buildFormattedField(Formatter.phoneNumber),
  WalletBank: buildFormattedField(Formatter.bankName),
}
