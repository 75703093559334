export enum Currency {
  CHIPS = 1,
  BRL = 2,
  GAMERSCOINS = 3,
}

export enum Gender {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}

export enum PersonType {
  NATURAL_PERSON = 'PF',
  JURIDICAL_PERSON = 'PJ',
}

export enum TransactionType {
  CASH_IN = 1,
  CASH_OUT = 2,
}

export enum TransactionSubType {
  P2P = 1,
  PIX = 2,
  EXCHANGE = 3,
  TED = 4,
  DOC = 5,
  DEPOSIT_CHIPS = 6,
  RESCUE_CHIPS = 7,
}

export enum WalletStatus {
  ACTIVE = 1,
  BLOCKED = 2,
  SUSPECTED_FRAUD = 3,
}

export enum DefaultAccount {
  INACTIVE,
  ACTIVE,
}

export enum StatusUserWalletAccount {
  DELETED,
  ACTIVE,
}

export enum TransactionTriageStatus {
  PENDING,
  DENIED,
}

export enum TransactionStatus {
  IN_PROCESS = 1,
  APPROVED = 2,
}

export enum BanklyTransactionStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
}

export enum AccountType {
  CHECKING = 'CHECKING',
  NULL = 'null',
}

export enum PersonaTypes {
  ORGANIC = 1,
  AFILIATE = 2,
  PLAYER = 3,
  PROMOTER_PF = 4,
  TEAM_PF = 5,
  PROMOTER_PJ = 6,
  TEAM_PJ = 7,
  COMERCIAL = 8,
}

export enum BrazilUf {
  AC = 'AC',
  AL = 'AL',
  AP = 'AP',
  AM = 'AM',
  BA = 'BA',
  CE = 'CE',
  DF = 'DF',
  ES = 'ES',
  GO = 'GO',
  MA = 'MA',
  MT = 'MT',
  MS = 'MS',
  MG = 'MG',
  PA = 'PA',
  PB = 'PB',
  PR = 'PR',
  PE = 'PE',
  PI = 'PI',
  RJ = 'RJ',
  RN = 'RN',
  RS = 'RS',
  RO = 'RO',
  RR = 'RR',
  SC = 'SC',
  SP = 'SP',
  SE = 'SE',
  TO = 'TO',
}
