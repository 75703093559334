import { get } from 'lodash'
import { Methods } from '../providers/dataProvider'
import Entities from './Entities'

const host = process.env.REACT_APP_API_URL

const routes = {
  // Autenticação: são utilizadas diretamente pelo AuthProvider
  auth: {
    LOGIN: '/auth/login',
    // Implementar funcionalidades de 2FA/ForgotPassword
    FORGOT_PASSWORD_START: '/auth/forgot-password',
    FORGOT_PASSWORD_CONFIRM_HASH: '/auth/forgot-password-confirm-hash',
    FORGOT_PASSWORD_CHANGE_PASSWORD: '/auth/forgot-password-change-password',
    TFA_QR_CODE: '/auth/tfa/qr-code',
    TFA_VERIFY: '/auth/tfa/verify',
  },
  // Entidades: são resolvidos pelo método resolveRoute deste arquivo para o DataProvider
  [Entities.USER]: {
    [Methods.update]: '/user',
  },
  [Entities.USER_ADDRESS]: {
    [Methods.update]: '/user-address',
  },
  [Entities.USER_PHONE]: {
    [Methods.update]: '/user-phone',
  },
  [Entities.BO_USER_GROUP]: {
    [Methods.getList]: '/back-office-groups',
    [Methods.update]: '/back-office-group',
    [Methods.create]: '/back-office-group',
    [Methods.getOne]: '/back-office-group',
  },
  [Entities.TRANSACTIONS]: {
    [Methods.getList]: '/bankly-events',
  },
  [Entities.TRANSACTIONS_TRIAGE]: {
    [Methods.getList]: '/wallet-solicitation-batch-event/triages',
  },
  [Entities.WALLET_USERS]: {
    [Methods.getList]: '/wallet/users-list',
    [Methods.getOne]: '/wallet/user',
    [Methods.update]: '/wallet/user',
  },
  [Entities.WALLET_SOLICITATIONS]: {
    [Methods.getList]: '/wallet/solicitations',
  },
  [Entities.WALLET_TRANSACTIONS]: {
    [Methods.getList]: '/wallet/user/transactions',
  },
  [Entities.WALLET_TRANSACTION_REASONS]: {
    [Methods.create]: '/transaction-wallet-reason',
    [Methods.update]: '/transaction-wallet-reason',
    [Methods.getOne]: '/transaction-wallet-reason',
    [Methods.getList]: '/transaction-wallet-reason',
  },
  [Entities.ACCOUNTS]: {
    [Methods.getOne]: '/accounts',
  },
  [Entities.WALLET_USER_ACCOUNTS]: {
    [Methods.getList]: '/wallet/user-account',
  },
  [Entities.WALLETS]: {
    [Methods.getList]: '/wallets',
  },
  [Entities.MY_PROFILE]: {
    [Methods.getOne]: '/back-office-user',
    [Methods.update]: '/back-office-user',
  },
  [Entities.TRANSACTION_WALLET]: {
    [Methods.getList]: '/transaction-wallet',
  },
  [Entities.WALLET_BALANCE]: {
    [Methods.getList]: '/wallet/balances',
  },
  [Entities.WALLET_NOTIFICATION]: {
    [Methods.getList]: '/wallet-notification-broadcast',
    [Methods.create]: '/notificate',
  },
  [Entities.WALLET_NOTIFICATION]: {
    [Methods.getList]: '/wallet-notification-broadcast',
    [Methods.create]: '/notificate',
  },
  [Entities.WALLET_PENDING_EXCHANGES]: {
    [Methods.getList]: '/pending-chip-money-exchanges',
    [Methods.update]: '/pending-chip-money-exchanges',
  },
}

export default {
  host,
  routes,
  resolveRoute: (method: Methods, resource: Entities): string => {
    const route = get(routes, [resource, method])
    if (!route) {
      throw new Error(`Unsupported ${method} for ${resource}`)
    }
    return route
  },
}
