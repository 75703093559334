import React from 'react'
import { Close as CloseIcon, Check as CheckIcon } from '@material-ui/icons'

import ConfirmModal from '../Modal/ConfirmModal'
import FieldProps from './FieldProps'

interface IApproveOrDenyFieldProps extends FieldProps {
  handleApprove: (id: number) => void
  handleDeny: (id: number) => void
}

const ApproveOrDenyField = (props: IApproveOrDenyFieldProps): JSX.Element => {
  return (
    <React.Fragment>
      <ConfirmModal
        tooltip="Aprovar"
        title="Aprovar"
        description="Deseja aprovar essa transacao?"
        handleConfirm={() => props.handleApprove(props.record.id)}
        icon={<CheckIcon />}
      />
      <ConfirmModal
        tooltip="Negar"
        title="Negar"
        description="Deseja negar essa transacao?"
        handleConfirm={() => props.handleDeny(props.record.id)}
        icon={<CloseIcon />}
      />
    </React.Fragment>
  )
}

export default ApproveOrDenyField
