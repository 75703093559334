import parsePhoneNumber from 'libphonenumber-js'
import { isNil } from 'lodash'

import { Currency } from '../config/Types'

export const RegularExpressions = {
  CPF: /^(\d{3})(\d{3})(\d{3})(\d{2})/,
  CNPJ: /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
}

const NumberFormatters = {
  BRL: new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }),
}

export const onlyNumbers = (value: string): string => value.replace(/\D/g, '')

/**
 * Alguns números foram inseridos no banco de dados sem a presença do código de país.
 * Esse método garante que os mesmos sejam retornados, com valor padrão 55.
 */
export const ensurePhoneCountryCode = (phoneNumber: string, usePlusSign = false): string => {
  const hasCountryCode = phoneNumber.length === 13
  const prefix = usePlusSign ? '+' : ''
  return hasCountryCode ? `${prefix}${phoneNumber}` : `${prefix}55${phoneNumber}`
}

/**
 * Esse método remove o código do país (que parece ser opcional nos retornos da API).
 */
export const removePhoneCountryCode = (phoneNumber: string): string => {
  const hasCountryCode = phoneNumber.length === 13
  return hasCountryCode ? phoneNumber.substring(2) : phoneNumber
}

export default {
  bankName: (walletBank: any): string => `${walletBank.code} - ${walletBank.name}`,
  brazilPersonDocument: (docNumber: string): string => {
    if (isNil(docNumber)) return ''
    switch (docNumber.length) {
      case 14: // CNPJ
        return docNumber.replace(RegularExpressions.CNPJ, '$1.$2.$3/$4-$5')
      case 11: // CPF
        return docNumber.replace(RegularExpressions.CPF, '$1.$2.$3-$4')
      default:
        return docNumber
    }
  },
  currency: (currency: Currency, amount = 0): string => {
    if (isNil(amount)) return ''
    switch (currency) {
      case Currency.BRL: {
        return NumberFormatters.BRL.format(amount)
      }
      case Currency.CHIPS:
      case Currency.GAMERSCOINS: {
        return amount.toLocaleString('pt-br', { minimumFractionDigits: 2 })
      }
      default: {
        return `${amount}`
      }
    }
  },
  shortDate: (dateStr: string): string => new Date(dateStr.replace('Z', '')).toLocaleDateString(),
  phoneNumber: (phoneNumber: string): string => {
    if (!phoneNumber) return ''
    const completeNumber = ensurePhoneCountryCode(phoneNumber, true)
    return parsePhoneNumber(completeNumber)?.format('NATIONAL') ?? ''
  },
}
