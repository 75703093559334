import * as React from 'react'
import { TopToolbar, ExportButton } from 'react-admin'
import ClearFiltersButton from '../Buttons/ClearFiltersButton'
import Constants from '../../../config/Constants'

const CommonListActions = (): JSX.Element => {
  return (
    <TopToolbar>
      <ClearFiltersButton />
      <ExportButton maxResults={Constants.exportButton.maxResults} />
    </TopToolbar>
  )
}

export default CommonListActions
