import React from 'react'
import { Filter, FilterProps, Identifier, RecordMap, SelectInput, useGetList, useTranslate } from 'react-admin'

import { Entities } from '../../../config'
import { Currency } from '../../../config/Types'
import Formatter from '../../../utils/Formatter'

interface SearchFiltersProps extends Omit<FilterProps, 'children'> {
  userId: Identifier
}

const useCurrencyChoiceFormat = (currency: Currency, balances: RecordMap) => {
  const balance = Object.values(balances)
    .filter((balance) => balance.walletCoin.id === currency)
    .reduce((n, { balance }) => n + balance, 0)

  const translate = useTranslate()
  const label = translate(`types.Currencies.${currency}`, { smart_count: 2 })

  const notShowChips = currency === Currency.CHIPS && balance <= 0

  return {
    id: notShowChips ? null : currency,
    name: `${label}: ${Formatter.currency(currency, balance)}`,
  }
}

const useCurrencyChoices = (balances: RecordMap) => {
  const brl = useCurrencyChoiceFormat(Currency.BRL, balances)
  const chips = useCurrencyChoiceFormat(Currency.CHIPS, balances)
  const gc = useCurrencyChoiceFormat(Currency.GAMERSCOINS, balances)

  if (!chips.id) {
    return [brl, gc]
  }

  return [brl, chips, gc]
}

const SearchFilters = (props: SearchFiltersProps): React.ReactElement => {
  const { userId } = props

  const { data: balances } = useGetList(
    Entities.WALLET_BALANCE,
    undefined,
    { field: 'WalletBalance.createdAt', order: 'DESC' },
    { userId }
  )

  const currencies = useCurrencyChoices(balances)

  return (
    <Filter {...props}>
      <SelectInput
        source="walletCoinId"
        label={`resources.${Entities.WALLET_TRANSACTIONS}.filters.walletCoin`}
        choices={currencies}
        translate="no"
        allowEmpty={false}
        alwaysOn
      />
    </Filter>
  )
}

export default SearchFilters
