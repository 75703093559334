import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import {
  Notifications as NotificationIcon,
  AttachMoney as AttachMoneyIcon,
  CallSplit as CallSplitIcon,
  People as PeopleIcon,
  FormatListNumbered as FormatListNumberedIcon,
} from '@material-ui/icons'

import * as providers from './providers'

import {
  EditWalletUser,
  ListGroups,
  CreateGroups,
  EditGroups,
  ListTransactions,
  ListTransactionsTriage,
  ListWalletUsers,
  ShowWalletUser,
  WelcomePage,
  ProfileEdit,
  ListTransactionWallet,
  CreateWalletTransactionReason,
  ListWalletTransactionReasons,
  EditWalletTransactionReason,
  ListNotifications,
  CreateNotifications,
  ListWalletPendingExchanges,
} from './modules'

import { Entities } from './config'

import ShieldedResource from './modules/common/RA-Overrides/ShieldedResource'
import MyLayout from './modules/common/RA-Overrides/Layout'

const App: React.FunctionComponent = () => (
  <Admin {...providers} dashboard={WelcomePage} layout={MyLayout}>
    <Resource name={Entities.ACCOUNTS} />
    <ShieldedResource name={Entities.BO_USER_GROUP} list={ListGroups} create={CreateGroups} edit={EditGroups} />
    <ShieldedResource name={Entities.TRANSACTIONS} list={ListTransactions} />
    <ShieldedResource name={Entities.TRANSACTIONS_TRIAGE} list={ListTransactionsTriage} icon={CallSplitIcon} />
    <ShieldedResource
      name={Entities.WALLET_USERS}
      list={ListWalletUsers}
      edit={EditWalletUser}
      show={ShowWalletUser}
      icon={PeopleIcon}
    />
    <ShieldedResource name={Entities.WALLET_SOLICITATIONS} />
    <Resource name={Entities.WALLET_USER_ACCOUNTS} />
    <Resource name={Entities.WALLET_TRANSACTIONS} />
    <Resource name={Entities.WALLET_BALANCE} />
    <Resource
      name={Entities.WALLET_TRANSACTION_REASONS}
      create={CreateWalletTransactionReason}
      edit={EditWalletTransactionReason}
      list={ListWalletTransactionReasons}
      icon={FormatListNumberedIcon}
    />
    <Resource name={Entities.WALLETS} />
    <Resource name={Entities.MY_PROFILE} edit={ProfileEdit} />
    <ShieldedResource name={Entities.TRANSACTION_WALLET} list={ListTransactionWallet} icon={AttachMoneyIcon} />
    <ShieldedResource
      name={Entities.WALLET_NOTIFICATION}
      list={ListNotifications}
      create={CreateNotifications}
      icon={NotificationIcon}
    />
    <ShieldedResource
      name={Entities.WALLET_PENDING_EXCHANGES}
      list={ListWalletPendingExchanges}
      icon={AttachMoneyIcon}
    />
  </Admin>
)

export default App
