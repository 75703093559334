import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  DateInput,
  FilterProps,
  PaginationProps,
  Pagination,
  ResourceComponentPropsWithId,
  TextInput,
  useUpdate,
  useNotify,
} from 'react-admin'
import { Currency } from '../../config/Types'
import MonetaryField from '../common/Fields/MonetaryField'
import WalletUserLinkField from '../common/Fields/WalletUserLinkField'
import ApproveOrDenyField from '../common/Fields/ApproveOrDenyField'
import { Entities } from '../../config'

const ListWalletPendingExchanges = (props: ResourceComponentPropsWithId): JSX.Element => {
  const [update] = useUpdate()
  const notify = useNotify()

  const SearchFilters = (props: Omit<FilterProps, 'children'>): React.ReactElement => (
    <Filter {...props}>
      <DateInput source="dateBegin" alwaysOn />
      <DateInput source="dateEnd" alwaysOn />
      <TextInput source="fullName" alwaysOn />
      <TextInput source="team" alwaysOn />
    </Filter>
  )

  const ListPagination = (props: PaginationProps): React.ReactElement => (
    <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100]} />
  )

  const handleConfirm = (approve: boolean, id: number) => {
    update(Entities.WALLET_PENDING_EXCHANGES, id, { approve })

    notify('ra.notification.updated', 'success')
  }

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<SearchFilters />}
      pagination={<ListPagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <DateField source="createdAt" showTime />
        <MonetaryField source="originValue" currencyTypeIdSource={Currency.CHIPS} />
        <MonetaryField source="destinyValue" />
        <TextField source="denyReason" />
        <WalletUserLinkField source="fullName" idSource="userId" />
        <ApproveOrDenyField
          handleApprove={(id) => handleConfirm(true, id)}
          handleDeny={(id) => handleConfirm(false, id)}
          source="id"
        />
      </Datagrid>
    </List>
  )
}

export default ListWalletPendingExchanges
