import React from 'react'
import { Filter, FilterProps, SelectInput, useTranslate } from 'react-admin'

import { Entities } from '../../../config'

const SearchFilters = (props: Omit<FilterProps, 'children'>): React.ReactElement => {
  const translate = useTranslate()

  const status = [
    { id: false, name: translate(`resources.${Entities.WALLET_USER_ACCOUNTS}.status.active`) },
    { id: true, name: translate(`resources.${Entities.WALLET_USER_ACCOUNTS}.status.inactive`) },
  ]

  return (
    <Filter {...props}>
      <SelectInput
        source="deletedAt"
        label={`resources.${Entities.WALLET_USER_ACCOUNTS}.filters.status`}
        choices={status}
        allowEmpty={true}
        alwaysOn
      />
    </Filter>
  )
}

export default SearchFilters
