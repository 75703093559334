import React from 'react'
import { Datagrid, DateField, TextField } from 'react-admin'

import { Locales } from '../../../config'

import MonetaryField from '../../common/Fields/MonetaryField'

const UserTransactionsDatagrid = (): React.ReactElement => {
  return (
    <Datagrid>
      <DateField source="createdAt" showTime options={{ timezone: Locales.BROWSER_TIMEZONE }} sortable={false} />
      <MonetaryField
        source="value"
        transactionTypeIdSource="transactionWalletType.id"
        currencyTypeIdSource="walletCoin.id"
        colored
        sortable={false}
      />
      <TextField source="transactionSubType.name" sortable={false} />
      <TextField source="account.companyName" sortable={false} />
      <TextField source="description" sortable={false} />
    </Datagrid>
  )
}

export default UserTransactionsDatagrid
